import { useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import '../style.css'

import { useQuery } from 'react-query';

import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { Input, Button } from '../../form-controls';
import { toast } from "react-toastify";


import AppLogo from '../../../assets/images/schoolzprologo-dark.png'
import AppLogoLight from '../../../assets/images/schoolzprologo.png'
import AppTrademark from '../../../assets/images/schoolzpro-trademark.png'

import { tokenFetch, erpTokenFetch } from '../../../api/tokenFetch'
import apiResources from '../../../api/api.constants';
import { AuthContext } from '../../../context/AuthContext'
import { FetchContext } from '../../../context/FetchContext'

const schema = yup.object({
  email: yup.string().email('Invalid Email').required('Email is required.'),
  password: yup.string().required('Password is required.'),
}).required();

const defaultValues = {
  email: '',
  password: '',
}

const SignIn = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const {
    authState,
    setAuthInfo,
    setErpAuthInfo
  }: any = useContext(AuthContext)
  const { publicFetch, publicErpFetch }: any = useContext(FetchContext)
  
  const slug = location.pathname.split('/')[1]
  
  const getKeys = async () => {
    const response = await tokenFetch.post(apiResources.getAuthIdBySlug, { slug })
    return response.data
  }

  useQuery('authId', getKeys,
    {
      enabled: !!slug,
      retry: 0,
      onSettled: (res: any) => {
        setAuthInfo(res)
      }
    }
  )

  // Setting keys for ERP
  const getErpKeys = async () => {
    const data = { secret_key: authState.userInfo.secretKey }
    const response = await erpTokenFetch.post(apiResources.getErpClientToken, data)
    return response.data.data
  }

  useQuery('erpAuthId', getErpKeys,
    {
      enabled: !!authState.userInfo.secretKey,
      retry: 0,
      onSettled: (res: any) => {
        setErpAuthInfo(res)
      }
    }
  )

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues: defaultValues, mode: 'all', resolver: yupResolver(schema) })
  
  const handleLoginWithErp = () => {
    handleSubmit(async (credentials: any) => {
      try {
        const { data: { data } } = await publicErpFetch.post(apiResources.loginWithErp, credentials)
        
        // Check whether the erp user is an admin
        if(![1, 2].includes(data.data.role_id)) {
          return toast.error("Either email or password is wrong.")
        } else {
          data.data.role = 'admin'
        }

        data.accessToken = authState.token
        data.data.firstName = data.data.fname
        data.data.secretKey = authState.userInfo.secretKey
        setAuthInfo(data)
    
        navigate(`/test/dashboard`)
        toast.success("Welcome Back!")
      } catch (err) {
        toast.error("Either email or password is wrong.")
      }
    })()
  }

  const onSubmit = async (credentials: any) => {
    try {
      const { data } = await publicFetch.post(apiResources.login, credentials)
      data.data.secretKey = authState.userInfo.secretKey
      setAuthInfo(data)
  
      if(slug) {
        navigate(`/test/dashboard`)
      } else {
        navigate(`/dashboard`)
      }
      toast.success("Welcome Back!")
    } catch (err) {
      toast.error("Either email or password is wrong.")
    }
  }

  return (
    <div className="auth__container">
      <div className="auth__bg d-none d-lg-block"></div>
      <div className="auth__content p-3 p-lg-0">
        <figure className="auth__logo">
          <img src={AppLogo} alt="logo" />
        </figure>
        <form className='auth__form mt-5' onSubmit={handleSubmit(onSubmit)}>
          <h1 className='mb-4 fw-normal'>Login</h1>
          <Input
            name='email'
            label='Email Address'
            size='large'
            control={control}
            errors={errors}
          />
          <div className='position-relative'>
            <Input
              type='password'
              name='password'
              label='Password'
              size='large'
              control={control}
              errors={errors}
            />
            <Link to='/forgot-password' className='forgot-password-link'>Forgot Password?</Link>
          </div>
          <Button
            className='mt-3'
            type='submit'
            fullWidth
            size='large'
          >
            Login
          </Button>
          {authState.userInfo.secretKey && (
            <>
              <p className='text-center my-2'>OR</p>
              <Button
                type='button'
                fullWidth
                size='large'
                color='primary'
                onClick={handleLoginWithErp}
                style={{ fontWeight: 'bold' }}
              >
                <img src={AppTrademark} style={{ width: 20, marginRight: 8 }} alt="logo" /> Login with Schoolzpro ERP
              </Button>
              {/* <Button
                type='button'
                fullWidth
                size='large'
                color='primary'
                onClick={handleLoginWithErp}
                style={{ fontWeight: 'bold' }}
              >
                Login with <img src={AppLogoLight} style={{ width: 80, margin: '0 8px' }} alt="logo" /> ERP
              </Button> */}
            </>
          )}
        </form>
      </div>
    </div>
  )
}

export default SignIn