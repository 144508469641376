import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmDialog({
  title,
  description = '',
  primary = 'Submit',
  primaryColor = '',
  secondary = 'Cancel',
  open,
  onTrigger
}: any) {

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => onTrigger(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ '& .MuiPaper-root': { borderRadius: 3 } }}
        maxWidth="xs"
      >
        <DialogTitle sx={{ pb: 0, fontWeight: 'bold' }}>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3, flexDirection: 'column' }}>
          <Button fullWidth onClick={() => onTrigger(true)} variant='contained' sx={{ mb: 2 }} disableElevation color={primaryColor} autoFocus>
            {primary}
          </Button>
          <Button fullWidth variant='outlined' color='inherit' sx={{ marginLeft: '0 !important' }} onClick={() => onTrigger(false)}>{secondary}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
