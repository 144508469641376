import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, FormProvider, useFormContext, useWatch } from 'react-hook-form';
import { useQuery } from 'react-query'
import apiResources from '../../../api/api.constants'
import { useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext'
import { FetchContext } from '../../../context/FetchContext'
import {tokenFetch, erpTokenFetch} from '../../../api/tokenFetch'
import ErrorLogo from '../../../assets/images/error.png'
import AppLogo from '../../../assets/images/schoolzprologo.png'
import './style.css'
import { 
  TextField, 
  MenuItem, 
  Select as MuiSelect, 
  FormControl, 
  InputLabel, 
  Autocomplete,
  RadioGroup as MuiRadioGroup,
  Radio,
  FormLabel,
  FormControlLabel
} from "@mui/material";
import lodash from 'lodash';
import {
  Input,
  DateInput,
  Select,
  RadioGroup,
  Button,
  GeoAutocomplete
} from '../../form-controls';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const EMAIL_REGEXP = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

const defaultValues = {
  schoolName: '',
  campus: '',
  academicYear: '',
  firstName: '',
  lastName: '',
  studentEmail: '',
  dateOfBirth: null,
  admissionInClass: '',
  gender: 'male',
  nationality: '',
  currentSchool: '',
  fatherFirstName: '',
  fatherLastName: '',
  fatherMobileNo: '',
  fatherEmail: '',
  fatherQualification: '',
  fatherProfession: '',
  fatherAnnualIncome: '',
  fatherOfficeName: '',
  motherFirstName: '',
  motherLastName: '',
  motherMobileNo: '',
  motherEmail: '',
  motherQualification: '',
  motherProfession: '',
  motherAnnualIncome: '',
  motherOfficeName: '',
  address: '',
  latitude: '',
  longitude: '',
  enquirySource: '',
  isStaffChild: 'no',
  hasJointFamily: 'no',
  hasSingleParent: 'no',
  isDisabledChild: 'no',
  paymentMode: 'online' // 'offline'
}

// const defaultValues = {
//   schoolName: '',
//   campus: 1,
//   academicYear: 2,
//   firstName: 'Rohit',
//   lastName: 'Kumar',
//   studentEmail: 'rohit@mailinator.com',
//   dateOfBirth: '2008-11-09',
//   admissionInClass: 1,
//   gender: 'male',
//   nationality: 'Indian',
//   currentSchool: 'Delhi Public School',
//   fatherFirstName: 'Sachin',
//   fatherLastName: 'Kumar',
//   fatherMobileNo: '9311923012',
//   fatherEmail: 'sachin@mailinator.com',
//   fatherQualification: 'job',
//   fatherProfession: 'Project Manager',
//   fatherAnnualIncome: '1000000',
//   fatherOfficeName: 'Infosys',
//   motherFirstName: 'Parul',
//   motherLastName: 'Kumar',
//   motherMobileNo: '9311923012',
//   motherEmail: 'parul@mailinator.com',
//   motherQualification: 'job',
//   motherProfession: 'Project Manager',
//   motherAnnualIncome: '1000000',
//   motherOfficeName: 'Infosys',
//   address: '',
//   latitude: '',
//   longitude: '',
//   enquirySource: 'offline',
//   isStaffChild: 'no',
//   hasJointFamily: 'no',
//   hasSingleParent: 'no',
//   isDisabledChild: 'no',
//   paymentMode: 'online'
// }

const SchoolDetails = () => {
  const { authState }: any = useContext(AuthContext)
  const { authFetch, authErpFetch }: any = useContext(FetchContext)
  
  let sessionOptions = []

  const getSessionMaster = async () => {
    const { data: response } = await authFetch.post(apiResources.getSessions)
    return response.data
  }

  const getErpSessionMaster = async () => {
    const { data: response } = await authErpFetch.get(apiResources.getErpSessions)
    return response
  }

  const sessionMaster = useQuery('sessionList', authState.userInfo.secretKey ? getErpSessionMaster : getSessionMaster)
  if (sessionMaster.isFetched) {
    sessionOptions = sessionMaster.data?.map((item: any) => ({ label: item.name, value: item.id }))
  }

  const getCampusMaster = async () => {
    const { data: response } = await authFetch.post(apiResources.getCampuses)
    return response.data
  }

  const getErpCampusMaster = async () => {
    const { data: response } = await authErpFetch.get(apiResources.getErpCampuses)
    return response.campus.data
  }

  let campusOptions = []
  const campusMaster = useQuery('campusList', authState.userInfo.secretKey ? getErpCampusMaster : getCampusMaster)
  if (campusMaster.isFetched) {
    campusOptions = campusMaster.data?.map((item: any) => ({ label: item.name, value: item.id }))
  }

  const { control, setValue, formState: { errors } } = useFormContext()

  useEffect(() => {
    setValue('schoolName', authState.userInfo.name)
  }, [])

  return (
    <>
      <div className='col-12'>
        <Input
          required
          name='schoolName'
          label='School Name'
          control={control}
          errors={errors}
          readOnly
          rules={{
            required: { value: true, message: 'School name is required.' }
          }}
        />
      </div>
      <div className='col-12'>
        <Select
          required
          name='campus'
          label='Campus'
          control={control}
          errors={errors}
          options={campusOptions}
          rules={{
            required: { value: true, message: 'Campus is required.' }
          }}
        />
      </div>
      <div className='col-12'>
        <Select
          required
          name='academicYear'
          label='Academic Year'
          control={control}
          errors={errors}
          options={sessionOptions}
          rules={{
            required: { value: true, message: 'Academic Year is required.' }
          }}
        />
      </div>
    </>
  )
}

const StudentDetails = () => {
  const { authState, setRegistrationOptions }: any = useContext(AuthContext)
  const { authFetch, authErpFetch }: any = useContext(FetchContext)
  const selectedClassId = useWatch({name: "admissionInClass"});
  const selectedCampusId = useWatch({name: "campus"});

  let classOptions: any[] = [];

  useEffect(()=> {
    if(selectedClassId){
      checkRegistrationEnabled(selectedClassId);
    }
  }, [selectedClassId])

  useEffect(()=> {
    if(selectedClassId){
      checkRegistrationEnabled(selectedClassId);
    }
  }, [selectedClassId])

  const getClassMaster = async () => {
    const { data: response } = await authFetch.post(apiResources.getClasses)
    return response.data
  }

  const getErpClassMaster = async () => {
    const { data: response } = await authErpFetch.get(apiResources.getErpClasses)
    return response.courses
  }

  const classMaster = useQuery('classList', authState.userInfo.secretKey ? getErpClassMaster : getClassMaster)
  if (classMaster.isFetched) {
    classOptions = classMaster.data.map((item: any) => ({ label: item.name, value: item.id, options: item.options }))
  }

  const getERPPaymentConfig = async () => {
    const { data: response } = await authErpFetch.get(`${apiResources.getErpConfiguration}&campus_id=${selectedCampusId}`);
    return response?.data?.original;
  }
  
  const paymentConfig: any = useQuery('configuration', authState.userInfo.secretKey && getERPPaymentConfig)
  if(paymentConfig.isFetched && !!paymentConfig?.data?.razorpay_key){
    localStorage.setItem('admissions@scp@erpPaymentKey', paymentConfig?.data?.razorpay_key)
  }

  const checkRegistrationEnabled = (classId: string | number) => {
    let classData = classOptions?.find(item => (item?.value == classId));
    setRegistrationOptions(classData?.options || null);
  }

  const { control, formState: { errors } } = useFormContext()

  return (
    <>
      <div className='col-lg-6'>
        <Input
          required
          name='firstName'
          label='First Name'
          control={control}
          errors={errors}
          rules={{
            required: { value: true, message: 'First name is required.' }
          }}
        />
      </div>
      <div className='col-lg-6'>
        <Input
          required
          name='lastName'
          label='Last Name'
          control={control}
          errors={errors}
          rules={{
            required: { value: true, message: 'Last name is required.' }
          }}
        />
      </div>
      <div className='col-lg-6'>
        <Input
          name='studentEmail'
          label='Email'
          control={control}
          errors={errors}
          rules={{
            required: { value: true, message: 'Email is required.' },
            email: { value: true, message: 'Invalid Email' }
          }}
        />
      </div>
      <div className='col-lg-6'>
        <DateInput
          required
          maxDate={new Date()}
          name='dateOfBirth'
          label='Date of Birth'
          control={control}
          errors={errors}
          rules={{
            required: { value: true, message: 'DOB is required.' }
          }}
        />
      </div>
      <div className='col-lg-6'>
        <Select
          required
          name='admissionInClass'
          label='Admission in Class'
          control={control}
          errors={errors}
          options={classOptions}
          rules={{
            required: { value: true, message: 'Class is required.' }
          }}
        />
      </div>
      <div className='col-lg-6'>
        <Select
          required
          name='gender'
          label='Gender'
          control={control}
          errors={errors}
          options={[
            { label: 'Male', value: 'male' },
            { label: 'Female', value: 'female' }
          ]}
        />
      </div>
      <div className='col-lg-6'>
        <Input
          required
          name='nationality'
          label='Nationality'
          control={control}
          errors={errors}
          rules={{
            required: { value: true, message: 'Nationality is required.' }
          }}
        />
      </div>
      <div className='col-lg-6'>
        <Input
          required
          name='currentSchool'
          label='Current School/Play School'
          control={control}
          rules={{
            required: { value: true, message: 'Current school is required.' }
          }}
        />
      </div>
    </>
  )
}

const FatherDetails = () => {
  const { control, formState: { errors } } = useFormContext()
  return (
    <>
      <div className='col-lg-6'>
        <Input
          required
          name='fatherFirstName'
          label='First Name'
          control={control}
          errors={errors}
          rules={{
            required: { value: true, message: 'First name is required.' }
          }}
        />
      </div>
      <div className='col-lg-6'>
        <Input
          required
          name='fatherLastName'
          label='Last Name'
          control={control}
          errors={errors}
          rules={{
            required: { value: true, message: 'Last name is required.' }
          }}
        />
      </div>
      <div className='col-lg-6'>
        <Input
          required
          name='fatherMobileNo'
          label='Mobile Number'
          control={control}
          errors={errors}
          rules={{
            required: { value: true, message: 'Mobile number is required.' }
          }}
        />
      </div>
      <div className='col-lg-6'>
        <Input
          required
          name='fatherEmail'
          label='Email'
          control={control}
          errors={errors}
          rules={{
            required: { value: true, message: 'Email is required.' }
          }}
        />
      </div>
      <div className='col-lg-6'>
        <Input
          required
          name='fatherQualification'
          label='Qualification'
          control={control}
          errors={errors}
          rules={{
            required: { value: true, message: 'Qualification is required.' }
          }}
        />
      </div>
      <div className='col-lg-6'>
        <Input
          required
          name='fatherProfession'
          label='Profession'
          control={control}
          errors={errors}
          rules={{
            required: { value: true, message: 'Profession is required.' }
          }}
        />
      </div>
      <div className='col-lg-6'>
        <Input
          name='fatherAnnualIncome'
          label='Annual income in Rupees'
          control={control}
          errors={errors}
          rules={{
            required: { value: true, message: 'Income is required.' }
          }}
        />
      </div>
      <div className='col-lg-6'>
        <Input
          name='fatherOfficeName'
          label='Office Name'
          control={control}
          errors={errors}
        />
      </div>
    </>
  )
}

const MotherDetails = () => {
  const { control, formState: { errors } } = useFormContext()

  return (
    <>
      <div className='col-lg-6'>
        <Input
          required
          name='motherFirstName'
          label='First Name'
          control={control}
          errors={errors}
          rules={{
            required: { value: true, message: 'First name is required.' }
          }}
        />
      </div>
      <div className='col-lg-6'>
        <Input
          required
          name='motherLastName'
          label='Last Name'
          control={control}
          errors={errors}
          rules={{
            required: { value: true, message: 'Last name is required.' }
          }}
        />
      </div>
      <div className='col-lg-6'>
        <Input
          required
          name='motherMobileNo'
          label='Mobile Number'
          control={control}
          errors={errors}
          rules={{
            required: { value: true, message: 'Mobile number is required.' }
          }}
        />
      </div>
      <div className='col-lg-6'>
        <Input
          name='motherEmail'
          label='Email'
          control={control}
          errors={errors}
        />
      </div>
      <div className='col-lg-6'>
        <Input
          required
          name='motherQualification'
          label='Qualification'
          control={control}
          errors={errors}
          rules={{
            required: { value: true, message: 'Qualification is required.' }
          }}
        />
      </div>
      <div className='col-lg-6'>
        <Input
          name='motherProfession'
          label='Profession'
          control={control}
          errors={errors}
        />
      </div>
      <div className='col-lg-6'>
        <Input
          name='motherAnnualIncome'
          label='Annual income in Rupees'
          control={control}
          errors={errors}
        />
      </div>
      <div className='col-lg-6'>
        <Input
          name='motherOfficeName'
          label='Office Name'
          control={control}
          errors={errors}
        />
      </div>
    </>
  )
}

const Address = () => {
  const { control, setValue, formState: { errors } } = useFormContext()
  return (
    <>
      <div className='col-lg-12'>
        <GeoAutocomplete
          control={control}
          errors={errors}
          rules={{ required: { value: true, message: 'Address is required.' } }}
          setValue={setValue}
          name='address'
          label='Address'
        />
      </div>
    </>
  )
}

const OtherDetails = () => {
  const { control, formState: { errors } } = useFormContext()
  return (
    <>
      <div className='col-lg-12'>
        <Select
          required
          name='enquirySource'
          label='Enquiry Source'
          control={control}
          errors={errors}
          options={[
            { label: 'Internet', value: 'internet' },
            { label: 'Offline', value: 'offline' }
          ]}
        />
      </div>
      <div className='col-lg-6'>
        <RadioGroup
          name='isStaffChild'
          label='Whether child is a staff ward'
          control={control}
          errors={errors}
          options={[
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' }
          ]}
        />
      </div>
      <div className='col-lg-6'>
        <RadioGroup
          name='hasJointFamily'
          label='Joint Family'
          control={control}
          errors={errors}
          options={[
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' }
          ]}
        />
      </div>
      <div className='col-lg-6'>
        <RadioGroup
          name='hasSingleParent'
          label='Are you Single Parent'
          control={control}
          errors={errors}
          options={[
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' }
          ]}
        />
      </div>
      <div className='col-lg-6'>
        <RadioGroup
          name='isDisabledChild'
          label='Physically/Mentally Challenged'
          control={control}
          errors={errors}
          options={[
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' }
          ]}
        />
      </div>
      <div className='col-lg-6'>
        <RadioGroup
          name='paymentMode'
          label='Fee payment mode'
          control={control}
          errors={errors}
          options={[
            { label: 'Pay Online', value: 'online' },
            { label: 'Pay At Counter', value: 'offline' }
          ]}
        />
      </div>
    </>
  )
}

const getStepContent = (step: any) => {
  switch (step) {
    case 0:
      return <SchoolDetails />
    case 1:
      return <StudentDetails />
    case 2:
      return <FatherDetails />
    case 3:
      return <MotherDetails />
    case 4:
      return <Address />
    case 5:
      return <OtherDetails />
    default:
      return null
  }
}

const erpSteps = [
  "School Details",
  "Student Details",
  "Father's Details",
  "Mother's Details",
  "Address",
  "Other Details",
]

interface CRMApplicationFormType {
  customLeadForm: any;
  currentPosition: number;
  setCurrentPosition: (position: number) => void;
  handleBack: () => void;
  steps: any[];
}

const CRMApplicationForm = ({
  customLeadForm = null, 
  currentPosition = 0,
  setCurrentPosition = () => null,
  handleBack = () => null,
  steps = []
}: CRMApplicationFormType) => {
  const navigate = useNavigate();
  const location = useLocation();
  const slug = location.pathname.split('/')[1];
  const { authFetch, authCrmFetch, authErpFetch }: any = useContext(FetchContext)
  const [allMasterData, setAllMasterData] = useState([] as any[]);
  const [allAcademicData, setAllAcademicData] = useState([] as any[]);
  const [customTemplate, setCustomTemplate] = useState(null as any);
  const [selectedProspects, setSelectedProspects] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { authState }: any = useContext(AuthContext)
  const [selectedCurrentInputData, setSelectedCurrentInputData] = useState<any>(null);
  const [address, setAddress] = useState("");
  const [latLong, setLatLong] = useState(null as any);

  useEffect(()=>{
    getMasterData();
    if(!!authState.userInfo.crmAccessKey){
      getCRMAcademicData();
    }else if(authState.userInfo.secretKey){
      getERPAcademicData();
    }
  }, [])

  const customTemplateMemo = useMemo(() => {
    if(customLeadForm && allMasterData?.length > 0 && allAcademicData?.length > 0){
      let leadInputFields: any = customLeadForm;
      leadInputFields?.sections?.map((section: string, index: number)=>{
        leadInputFields[section].currentPosition = index;
        if(leadInputFields[section]?.fields?.length > 0){
          leadInputFields[section]?.fields?.map((field: string)=> {
            const fieldJson: any = leadInputFields[section][field];
            if(fieldJson?.dataType == 'picklist' && !!fieldJson?.apiName){
              // leadInputFields[section][field]?.isOpenPopup == false;
              if(fieldJson?.apiName === "getProspectList"){
                let prospectList: any[] = allMasterData[1]?.leads || [];
                prospectList = allMasterData[1]?.leads?.map((lead: any) => ({
                  id: lead.id,
                  childName: lead.childName,
                  email: lead.email,
                  dob: lead.dob,
                  academicYear: lead.academicYear,
                  classApplied: lead.classApplied
                }))
                prospectList.push({
                  id: 'other',
                  childName: 'Other'
                })
                leadInputFields[section][field].pickListValues = prospectList || [];
                leadInputFields[section][field].value = 'other';
                leadInputFields[section][field].isUnusedField = true;
              }else if(fieldJson?.apiName === "getCampusList"){
                leadInputFields[section][field].pickListValues = allAcademicData[1]?.schools || [];
              }else if(fieldJson?.apiName === "getSessionList"){
                leadInputFields[section][field].pickListValues = allAcademicData[0]?.AcademicSessions || [];
              }else if(fieldJson?.apiName === "getClassList"){
                leadInputFields[section][field].pickListValues = allAcademicData[2]?.classes || [];
              }else if(fieldJson?.apiName === "getLeadPriority"){
                leadInputFields[section][field].pickListValues = allMasterData[5]?.queryPriorities || [];
                leadInputFields[section][field].value = 1;
              }else if(fieldJson?.apiName === "getLeadSource"){
                leadInputFields[section][field].pickListValues = allMasterData[4]?.leadSources || [];
              }else if(fieldJson?.apiName === "getLeadStatus"){
                leadInputFields[section][field].pickListValues = allMasterData[0]?.leadStages || [];
              }else if(fieldJson?.apiName === "getLeadList"){
                leadInputFields[section][field].pickListValues = allMasterData[3]?.leadCategories || [];
              }else if(fieldJson?.apiName === "account"){
                leadInputFields[section][field].pickListValues = allMasterData[2]?.accounts || [];
              }
            }else if(fieldJson?.dataType == 'date'){
              if(fieldJson?.fieldName === "dob"){
                leadInputFields[section][field].value = "2022-01-01";
              }
            }else if(fieldJson?.dataType == 'text' && fieldJson?.apiName == "houseNo"){
              setAddress(leadInputFields[section][field].value);
            }
          })
        }
      })
      return leadInputFields;
    }else{
      return null
    }
  }, [allMasterData, allAcademicData])

  useEffect(()=>{
    setCustomTemplate(customTemplateMemo);
  }, [customTemplateMemo])

  const getMasterData = async () => {
    let formData = new FormData();
    formData.append("locale", "en");
    let stageFormData = new FormData();
    stageFormData.append("locale", "en");
    stageFormData.append("filter", JSON.stringify({"sortBy":"sequence","orderBy":"asc"}));
    let leadFormData =  new FormData();
    leadFormData.append("locale", "en");
    leadFormData.append("page", "1");
    leadFormData.append("pageSize", "1000");
    let contactFormData = new FormData();
    contactFormData.append("locale", "en");
    contactFormData.append("page", "1");
    contactFormData.append("pageSize", "0");
    contactFormData.append("sortBy", "id");
    contactFormData.append("sortParam", "desc");
    contactFormData.append("filter", JSON.stringify({"isConverted":0}));
    contactFormData.append("attribute[]", "childName");
    contactFormData.append("attribute[]", "email");
    contactFormData.append("attribute[]", "dob");
    contactFormData.append("attribute[]", "academicYear");
    contactFormData.append("attribute[]", "parentType");
    contactFormData.append("attribute[]", "parentName");
    contactFormData.append("attribute[]", "classApplied");
    contactFormData.append("attribute[]", "admissionType");

    const stagePromise = authCrmFetch.post(apiResources.getStageList, stageFormData);
    const contactPromise = authCrmFetch.post(apiResources.getContactList, contactFormData);
    const accountPromise = authCrmFetch.post(apiResources.getAccountList, leadFormData);
    const leadPromise = authCrmFetch.post(apiResources.getLeadList, leadFormData);
    const sourcePromise = authCrmFetch.post(apiResources.getSourceList, formData);
    const queryPriorityPromise = authCrmFetch.post(apiResources.getQueryPriority, formData);
    try {
      const res = await Promise.all([stagePromise, contactPromise, accountPromise, leadPromise, sourcePromise, queryPriorityPromise]);
      const data: any = res.map((res) => res.data?._embedded);
      setAllMasterData(data.flat());
    } catch {
      throw Error("Promise failed");
    }
  }

  const getCRMAcademicData = async () => {
    let formData = new FormData();
    formData.append("locale", "en");
    formData.append("filter", JSON.stringify({"sortBy":"sequence","orderBy":"desc"}));

    const sessionPromise = authCrmFetch.post(apiResources.getCRMSessionList, formData);
    const schoolPromise = authCrmFetch.post(apiResources.getCRMSchoolList, formData);
    const classPromise = authCrmFetch.post(apiResources.getCRMClassList, formData);
    try {
      const res = await Promise.all([sessionPromise, schoolPromise, classPromise]);
      const data = res.map((res) => res.data?._embedded);
      setAllAcademicData(data.flat());
    } catch {
      throw Error("Promise failed");
    }
  }

  const getERPAcademicData = async () => {
    const sessionPromise = authErpFetch.get(apiResources.getErpSessions);
    const schoolPromise = authErpFetch.get(apiResources.getErpCampuses);
    const classPromise = authErpFetch.get(apiResources.getErpClasses);
    try {
      const res = await Promise.all([sessionPromise, schoolPromise, classPromise]);
      const data = res.map((res) => res);
      const resData = data.flat();
      const _data = [
        {"AcademicSessions": resData[0]?.data || []},
        {"schools": resData[1]?.data?.campus?.data || []},
        {"classes": resData[2]?.data?.courses || []}
      ]
      setAllAcademicData(_data);
    } catch {
      throw Error("Promise failed");
    }
  }

  const onContinue = () => {
    if(handleInputValidation()){
      if(currentPosition == customTemplate?.sections.length - 1){
        callLeadCreateHandler();
      }else{
        setIsSubmitted(false);
        setCurrentPosition(currentPosition + 1);
      }
    }
  };

  const handleInputValidation = () => {
    let isValid = true;
    setIsSubmitted(true);
    if(customTemplate?.sections?.length > 0){
      customTemplate?.sections?.map((section: string, i: number)=> {
        if(currentPosition === customTemplate[section]?.currentPosition){
          if(customTemplate[section]?.fields?.length > 0){
            customTemplate[section]?.fields?.map((field: any)=> {
              if(customTemplate[section][field]?.isUnusedField === false && customTemplate[section]?.accordionCollapsed == false){
                if(customTemplate[section][field]?.validationRule?.required && !customTemplate[section][field]?.value){
                  isValid = false;
                }
              }
            })
          }
        }
      });
    }
    return isValid;
  }

  const callLeadCreateHandler = async () => {
    let leadInputFields: any = JSON.parse(JSON.stringify(customTemplate));
    const leadPriorityKeyObj = getKeyFromObject("leadPriority");
    const firstNameKeyObj: string = getKeyFromObject("firstName");
    const lastNameKeyObj = getKeyFromObject("lastName");
    const leadSourceKeyObj = getKeyFromObject("leadSource");
    const dobKeyObj = getKeyFromObject("dob");
    const leadListObj = getKeyFromObject("leadList");
    let firstName = "";
    let leadPriority = "";
    let lastName = "";
    let leadSource = "";
    let leadListId = "";
    if(firstNameKeyObj) firstName = leadInputFields[firstNameKeyObj]["firstName"].value;
    if(leadPriorityKeyObj) leadPriority = leadInputFields[leadPriorityKeyObj]["leadPriority"].value;
    if(lastNameKeyObj) lastName = leadInputFields[lastNameKeyObj]["lastName"].value;
    if(leadSourceKeyObj) leadSource = leadInputFields[leadSourceKeyObj]["leadSource"].value;
    if(leadListObj) leadListId = leadInputFields[leadListObj]["leadList"].value;
    if(dobKeyObj) {
      let dataValue = leadInputFields[dobKeyObj]["dob"]?.value;
      if(dataValue){
        let date = {day: new Date(dataValue).getDay() + 1, year: new Date(dataValue).getFullYear(), month: new Date(dataValue).getMonth() + 1} 
        leadInputFields[dobKeyObj]["dob"].value = date;
      }
    }
    if(leadInputFields && leadListId){
      leadInputFields["leadList"] = leadListId;
    }
    let formData = new FormData();
    formData.append("dataJson", JSON.stringify(leadInputFields));
    if(selectedProspects && selectedProspects !== 'other') formData.append("leadID", selectedProspects)
    formData.append("source", leadSource)
    formData.append("priority", leadPriority)
    formData.append("remark", "Test remark");
    formData.append("title", `${firstName} ${lastName ?? ''}`);
    formData.append("latitude", latLong.lat?.toString());
    formData.append("longitude", latLong.lng?.toString());
    formData.append("locale", "en");
    const response = await authCrmFetch.post(apiResources.createLead, formData)

    if([200, 201].includes(response.status)) {
      navigate(`/${slug}/confirmation`);
    }
  }

  const getKeyFromObject = (key: string) => {
    return lodash.findKey(customTemplate, function(obj) { return obj[key] }) || "";
  }

  const onSetPickerValue = (section: string ,field: string, item: any) => {
    setIsSubmitted(false);
    let value = item?.target?.value;
    let leadInputFields: any = JSON.parse(JSON.stringify(customTemplate));
    if(leadInputFields[section][field].apiName == "getProspectList"){
      const firstNameKeyObj = getKeyFromObject("firstName");
      const academicYearKeyObj = getKeyFromObject("academicYear");
      const addmissionInClassKeyObj = getKeyFromObject("addmissionInClass");
      const dobKeyObj = getKeyFromObject("dob");
      const fatherEmailKeyObj = getKeyFromObject("fatherEmail");
      if(value != "other"){
        const _selectedProspects = allMasterData[1]?.leads.find((_item: any)=> _item.id == value);
        if(firstNameKeyObj) leadInputFields[firstNameKeyObj]["firstName"].value = _selectedProspects?.childName || "";
        if(academicYearKeyObj) leadInputFields[academicYearKeyObj]["academicYear"].value = _selectedProspects?.academicYear ? Number(_selectedProspects?.academicYear) : "";
        if(addmissionInClassKeyObj) leadInputFields[addmissionInClassKeyObj]["addmissionInClass"].value = _selectedProspects?.classApplied || "";
        let dateOfbirth = _selectedProspects?.dob; //{day: 1, year: 2015, month: 7}
        if(_selectedProspects?.dob && typeof _selectedProspects?.dob == "object"){
          dateOfbirth = _selectedProspects?.dob?.year+"-"+_selectedProspects?.dob?.month+"-"+_selectedProspects?.dob?.day
        } 

        if(dobKeyObj && dateOfbirth) leadInputFields[dobKeyObj]["dob"].value = dateOfbirth || "";
        if(fatherEmailKeyObj) leadInputFields[fatherEmailKeyObj]["fatherEmail"].value = _selectedProspects?.email || "";
      }else{
        if(firstNameKeyObj) leadInputFields[firstNameKeyObj]["firstName"].value = "";
        if(academicYearKeyObj) leadInputFields[academicYearKeyObj]["academicYear"].value = "";
        if(addmissionInClassKeyObj) leadInputFields[addmissionInClassKeyObj]["addmissionInClass"].value = "";
        if(dobKeyObj) leadInputFields[dobKeyObj]["dob"].value = "2022-01-01";
        if(fatherEmailKeyObj) leadInputFields[fatherEmailKeyObj]["fatherEmail"].value = "";
      }
      setSelectedProspects(value);
    }

    if(leadInputFields[section][field].dataType == "email"){
      if (!EMAIL_REGEXP.test(value)) {
        leadInputFields[section][field].error = "Please enter valid email address";
      }else {
        leadInputFields[section][field].error = "";
        // let email = item.replace(/\+/g, "%2B");
        setSelectedCurrentInputData({section, field});
        // checkEmailAvailable(email);
      }
    }
    leadInputFields[section][field].value = value;
    setCustomTemplate(leadInputFields);
  }

  const handleAddressSelect = async (e: any, section: string, field: string) => {
    const address = e.target.value;

    if(!address){
      setLatLong(null);
      setAddress('');
      let leadInputFields: any = JSON.parse(JSON.stringify(customTemplate));
      leadInputFields[section][field].value = "";
      leadInputFields[section]["city"].value = "";
      leadInputFields[section]["country"].value = "";
      leadInputFields[section]["state"].value = "";
      leadInputFields[section]["pinCode"].value = "";
      setCustomTemplate(leadInputFields);
      return
    }
    let results: any = await geocodeByAddress(address);
    if(results?.length > 0){
      getAddressInfo(results[0], section, field);
      let latLng = await getLatLng(results[0])
      setLatLong(latLng);
    }
  }

  const getAddressInfo = (result: any, section: string, field: string) => {
    if(!result){
      return null;
    }
    let houseNo = address;
    let city = result?.address_components?.find((item: any) => item.types.includes('administrative_area_level_2'))?.long_name ?? '';
    if(!city){
        city = result?.address_components?.find((item: any) => item.types.includes('administrative_area_level_3'))?.long_name ?? '';
    }
    let state = result?.address_components?.find((item: any) => item.types.includes('administrative_area_level_1'))?.long_name ?? '';
    let country = result?.address_components?.find((item: any) => item.types.includes('country'))?.long_name ?? '';
    let postalCode = result?.address_components?.find((item: any) => item.types.includes('postal_code'))?.long_name ?? '';
    
    let leadInputFields: any = JSON.parse(JSON.stringify(customTemplate));
    leadInputFields[section][field].value = houseNo || "";
    leadInputFields[section]["city"].value = city || "";
    leadInputFields[section]["country"].value = country || "";
    leadInputFields[section]["state"].value = state || "";
    leadInputFields[section]["pinCode"].value = postalCode || "";
    setCustomTemplate(leadInputFields);
  }

  return(
    <>
      {customTemplate && customTemplate?.sections?.length > 0 && customTemplate?.sections?.map((section: string, i: number)=> {
        if(currentPosition !== customTemplate[section]?.currentPosition){
          return null;
        }
        return(
          <div className='col-lg-12 custom-form-container' key={`${section}_1_${i}`}>
            {customTemplate[section]?.fields?.length > 0 && customTemplate[section]?.fields.map((field: string, j: number)=> {
              return(
                <>
                  {customTemplate[section][field]?.isUnusedField === false && customTemplate[section]?.accordionCollapsed == false && (
                    <div className={customTemplate[section][field]?.fieldName == 'houseNo' ? 'col-lg-12' : 'col-lg-6'} key={`${field}_2_${j}`}>
                      <div className='col-lg-12'>
                        {customTemplate[section][field]?.dataType !== 'file' && (
                          <>
                            {(customTemplate[section][field]?.dataType == 'picklist') ? (
                              <div className='field-style'>
                                <FormControl fullWidth size="small">
                                  <InputLabel id={customTemplate[section][field]?.labelName}>{`${customTemplate[section][field]?.labelName} ${!!customTemplate[section][field]?.validationRule?.required ? '*' : ''}`}</InputLabel>
                                  <MuiSelect
                                    className='textField'
                                    id={customTemplate[section][field]?.labelName}
                                    required={customTemplate[section][field]?.validationRule?.required}
                                    value={customTemplate[section][field]?.value}
                                    defaultValue={customTemplate[section][field]?.value}
                                    label={customTemplate[section][field]?.labelName}
                                    labelId={`${customTemplate[section][field]?.labelName}`}
                                    onChange={(value: any)=> onSetPickerValue(section, field, value)}
                                  >
                                    {customTemplate[section][field]?.pickListValues?.length > 0 && customLeadForm[section][field]?.pickListValues?.map((item: any, inx: number) => {
                                      return (
                                        <MenuItem key={`${inx}_${item?.id}`} value={item.id}>{item?.childName || item?.name}</MenuItem>
                                      )
                                    })}
                                  </MuiSelect>
                                </FormControl>
                              </div>
                            ) : (customTemplate[section][field]?.dataType == 'date') ? (
                              <div className='field-style'>
                                <TextField
                                  id={customTemplate[section][field]?.labelName}
                                  required={customTemplate[section][field]?.validationRule?.required}
                                  label={`${customTemplate[section][field]?.labelName}`}
                                  type="date"
                                  // defaultValue="Default Value"
                                  value={customTemplate[section][field]?.value || ""}
                                  onChange={(value)=> onSetPickerValue(section, field, value)}
                                  variant="outlined" 
                                  className='textField'
                                  size="small"
                                  // value="2022-02-25"
                                />
                              </div>
                            ) : (customTemplate[section][field]?.dataType == 'radio') ? (
                              <div className='field-style'>
                                <FormControl>
                                  <FormLabel id="demo-row-radio-buttons-group-label">{customTemplate[section][field]?.labelName} {!!customTemplate[section][field]?.validationRule?.required ? '*' : ''}</FormLabel>
                                  <MuiRadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={customTemplate[section][field]?.value || ""}
                                    onChange={(value)=> onSetPickerValue(section, field, value)}
                                  >
                                    {customTemplate[section][field]?.radioOptions?.map((item: any)=>(
                                      <FormControlLabel key={item} value={item} control={<Radio />} label={item} />
                                    ))}
                                  </MuiRadioGroup>
                                </FormControl>
                              </div>
                            ) : (customTemplate[section][field]?.fieldName == 'houseNo') ? (
                              <div className='field-style'>
                                <PlacesAutocomplete
                                  value={address}
                                  onChange={setAddress}
                                  // onSelect={(value) => handleAddressSelect(value, section, field)}
                                >
                                {({ getInputProps, suggestions }) => (
                                  <Autocomplete
                                    freeSolo
                                    disablePortal
                                    value={address}
                                    options={suggestions.map((suggestion: any) => suggestion.description)}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        {...getInputProps()}
                                        label={`${customTemplate[section][field]?.labelName}`}
                                        onSelect={(value) => handleAddressSelect(value, section, field)}
                                        onBlurCapture={(value) => handleAddressSelect(value, section, field)}
                                        size="small"
                                      />
                                    )}
                                  />
                                )}
                                </PlacesAutocomplete>
                              </div>
                            ) : (
                              <div className='field-style'>
                                <TextField
                                  id={customTemplate[section][field]?.labelName}
                                  required={customTemplate[section][field]?.validationRule?.required}
                                  label={`${customTemplate[section][field]?.labelName}`}
                                  type={customTemplate[section][field]?.dataType == 'number' ? "number" : "text"}
                                  // defaultValue="Default Value"
                                  value={customTemplate[section][field]?.value || ''}
                                  onChange={(value)=> onSetPickerValue(section, field, value)}
                                  variant="outlined" 
                                  className='textField'
                                  size="small"
                                />
                              </div>
                            )}
                            {!customTemplate[section][field]?.value && !!isSubmitted && customTemplate[section][field]?.validationRule?.required && (
                              <div className='form-error'>{customTemplate[section][field]?.labelName} is a required field.</div>
                            )}
                            {!!customTemplate[section][field]?.error && (
                              <div className='form-error'>{customTemplate[section][field]?.error}</div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  )}  
                </>
              )
            })}            
          </div>
        )
      })}
      <div className='row'>
        <div className='col-12 mb-3 d-flex justify-content-end'>
          {currentPosition !== 0 && (
            <Button
              color='inherit'
              onClick={handleBack}
            >
              Back
            </Button>
          )}
          <Button
            onClick={onContinue}
            className='ms-2'
          >
              {steps.length - 1 === currentPosition ? "Submit" : "Next"}
          </Button>
        </div>
      </div>
    </>
  )
}

const TestApplicationForm = () => {
  const [activeStep, setActiveStep] = useState(0)
  const [steps, setSteps] = useState(erpSteps);
  const [customLeadForm, setCustomLeadForm] = useState(null as any);
  const [unautorizedMsg, setUnautorizedMsg] = useState("");
  const navigate = useNavigate()
  const location = useLocation()
  const { setAuthInfo, setErpAuthInfo, setCRMAuthInfo, authState }: any = useContext(AuthContext)
  const { authFetch, authErpFetch, authCrmFetch, publicCrmFetch }: any = useContext(FetchContext)

  // Get essential auth data
  const slug = location.pathname.split('/')[1]

  const getKeys = async () => {
    const response = await tokenFetch.post(apiResources.getAuthIdBySlug, { slug })
    return response.data
  }

  const authData = useQuery('authId', getKeys,
    {
      retry: 0,
      onSettled: (res: any) => {
        setAuthInfo(res)
      }
    }
  )

  // Setting keys for ERP
  const getErpKeys = async () => {
    const data = { secret_key: authState.userInfo.secretKey }
    const response = await erpTokenFetch.post(apiResources.getErpClientToken, data)
    return response.data.data
  }

  const erpAuthData = useQuery('erpAuthId', getErpKeys,
    {
      enabled: !!authState.userInfo?.secretKey,
      retry: 0,
      onSettled: (res: any) => {
        setErpAuthInfo(res)
      }
    }
  )
 
  // Setting keys for CRM
  const getCRMAuthKeys = async () => {
    const data = { 
      refererUrl: window.location?.origin,
      clientID: authState.userInfo.crmClientID, 
      accessKey: authState.userInfo.crmAccessKey,
      // refererUrl: "https://admissions.schoolzpro.net.in"
    }
    const response = await publicCrmFetch.post(apiResources.getCrmClientToken, data)
    return response.data;
  }

  const crmAuthData = useQuery('crmAuthId', getCRMAuthKeys,
    {
      enabled: !!authState.userInfo?.crmAccessKey && !!authData?.isFetched,
      retry: 0,
      onSettled: (res: any) => {
        if(res?.attributes?.token){
          setCRMAuthInfo(res?.attributes?.token);
          setUnautorizedMsg("");
        }
        if(res?.status === 401){
          setUnautorizedMsg(res?.message || "");
        }
      }
    }
  )

  const getLeadTemplate = async () => {
    const response = await authCrmFetch.get(apiResources.leadTemplateDetail)
    return response?.data?.attributes;
  }

  const crmLeadTemplateData = useQuery('crmLeadTemplate', getLeadTemplate,
    {
      enabled: !!authState.crmToken && !!authData?.isFetched,
      retry: 0,
      onSettled: (res: any) => {
        calculateTemplate(res?.data);
      }
    }
  )

  const calculateTemplate = (templateData: any) => {
    if(templateData){
      let leadInputFields: any = templateData;
      let progressLabels: any[] = [];
      leadInputFields?.sections?.map((section: string, index: number)=>{
        progressLabels.push(getFormatedTitle(section));
      })
      setSteps(progressLabels);
      setCustomLeadForm(templateData);
    }
  }

  const getFormatedTitle = (text: string) => {
    const spaced = text.replace(/([a-z])([A-Z])/g, '$1 $2');
    return spaced.charAt(0).toUpperCase() + spaced.slice(1);
  }

  const methods = useForm({ defaultValues, mode: 'all' })

  const handleNext = async (data: any) => {
    if (activeStep === steps.length - 1) {
      if(authState.registrationOptions?.enable_registration == 1 && authState.registrationOptions?.enable_registration_fee == 1 && !!authState.registrationOptions?.registration_fee){
        if(methods.watch("paymentMode") == "online"){
          let key: any = await localStorage.getItem('admissions@scp@erpPaymentKey');
          if(key){
            initializeEaseBuzz(data, Number(authState.registrationOptions?.registration_fee));
          }else{
            // Give error for payment setup not active
          }
        }else{
          handlePaymentResponse(data);
        }
      }else{
        handlePaymentResponse(data);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }

  const initializeEaseBuzz = (data: any, amount: number) => {
    // Call initiate payment and open payment gateway windows
    // easybuzzPaymentCheckOut(res.data?.data?.data, data);
  }

  const easybuzzPaymentCheckOut = async (accessKey: string, data: any) => {
    if (!accessKey) {
      return;
    }
    let key: any = await localStorage.getItem('admissions@scp@erpPaymentKey');
    var easebuzzCheckout = new (window as any).EasebuzzCheckout(key, 'prod')
    var options = {
      access_key: accessKey, // access key received via Initiate Payment
      onResponse: (response: any) => {
        if (response && response?.status === "success") {
          // handlePaymentResponse(data, response);
          console.log('easebuzz response', response)
        }
      }
    }
    easebuzzCheckout.initiatePayment(options);
  }

  const handlePaymentResponse = async (data: any, paymentResponse?: any) => {
    // console.log(data)
    const payload = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.studentEmail,
      latitude: data.latitude?.toFixed(6).toString(),
      longitude: data.longitude?.toFixed(6).toString(),
      role: "student",
      formJSON: [data]
    }

    const erpPayload = {
      campus_id: 1,
      student_type: 'new',
      first_name: data.firstName,
      middle_name: null,
      last_name: data.lastName,
      first_guardian_name: `${data.fatherFirstName} ${data.fatherLastName}`,
      first_guardian_relation: 'father',
      second_guardian_name: `${data.motherFirstName} ${data.motherLastName}`,
      second_guardian_relation: 'mother',
      parent_type: 'new',
      first_guardian_contact_number_1: data.fatherMobileNo,
      date_of_birth: data.dateOfBirth,
      date_of_registration: new Date(),
      contact_number: data.fatherMobileNo,
      gender: data.gender,
      course_id: data.admissionInClass,
      locale: 'en',
    }

    const response = await authFetch.post(apiResources.createUser, payload)
    if([200, 201].includes(response.status)) {
      // Send data to ERP
      const resFromErp = await authErpFetch.post(apiResources.sendDataToErp, erpPayload)
      navigate(`/${slug}/confirmation`)
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  const fieldsHandler = () => {
    return (
      <section className='form-container'>
        <h3>{steps[activeStep]}</h3>
        <div className='container'>
          <div className='row'>
            {getStepContent(activeStep)}
            {(authState.registrationOptions?.enable_registration != 1 && activeStep > 0 && !!methods.getValues("admissionInClass")) && (
              <div className='errorMsg'>Registration closed for this class</div>
            )}
            <div className='d-flex'>
              <div className='col-4 mb-3'>
                {(!!authState.registrationOptions?.registration_fee && activeStep > 0 && authState.registrationOptions?.enable_registration_fee == 1) ? (
                  <label>Total Fee Amount - <span className='amountTxt'>₹{authState.registrationOptions?.registration_fee}</span></label>
                ) : (activeStep > 0 && authState.registrationOptions?.enable_registration_fee == 1 && !authState.registrationOptions?.registration_fee) && (
                  <label className='amountTxt'>No Fee Amount</label>
                )}
              </div>
              <div className='col-8 mb-3 d-flex justify-content-end'>
                {activeStep !== 0 && (
                  <Button
                    color='inherit'
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                )}
                <Button
                  type='submit'
                  className='ms-2'
                  disabled={(authState.registrationOptions?.enable_registration != 1 && activeStep > 0 && !!methods.getValues("admissionInClass"))}
                >
                  {steps.length - 1 === activeStep ? `${(authState.registrationOptions?.enable_registration_fee == 1 && !!authState.registrationOptions?.registration_fee && methods.watch("paymentMode") == "online")? `Pay ₹${authState.registrationOptions?.registration_fee} & Submit` : 'Submit'}` : 'Next'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }

  const crmFieldHandler = () => {
    return (
      <section className='form-container'>
        <h3>{steps[activeStep]}</h3>
        <div className='container'>
          <div className='row'>
            <CRMApplicationForm 
              customLeadForm={customLeadForm} 
              currentPosition={activeStep}
              setCurrentPosition={setActiveStep}
              handleBack={handleBack}
              steps={steps}
            />
          </div>
        </div>
      </section>      
    );
  }

  return (
    <div className='application-form'>
      <header className='header'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-4'>
              {authState.userInfo?.logo ? (
                <img src={authState.userInfo?.logo} className='imgLogo' alt='Schoolzpro Logo'/>
              ) : (
                <img src={AppLogo} alt='Schoolzpro Logo' /> 
              )}
            </div>
          </div>
        </div>
      </header>
      <main className='pt-3'>
        <div className='container'>
          <h1 className='form-heading'>Application Form</h1>
        </div>
        <FormProvider {...methods}>
          <form autoComplete="off" onSubmit={methods.handleSubmit(handleNext)} noValidate>
            <div className='container'>
              {(authData.isLoading || (authState.userInfo.secretKey && erpAuthData.isLoading) || (authState.userInfo.crmAccessKey && (crmAuthData?.isLoading || crmLeadTemplateData?.isLoading))) ? (
                <div className='col-lg-12'>
                  <section className='form-container' style={{ minHeight: 350, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p className="fw-bold">Loading...</p>
                  </section>
                </div>
              ) : (erpAuthData.status === "error" || crmAuthData.status === "error" || crmLeadTemplateData.status === "error" ) ? (
                <div className='col-lg-12'>
                  <section className='form-container' style={{ minHeight: 350, textAlign: 'center', paddingTop: 90 }}>
                    <img src={ErrorLogo} alt="logo" />
                    <p className="error-msg">Something went wrong</p>
                  </section>
                </div>
              ) : (!!unautorizedMsg && !!authState.userInfo.crmAccessKey) ? (
                <div className='col-lg-12'>
                  <section className='form-container' style={{ minHeight: 350, textAlign: 'center', paddingTop: 90 }}>
                    <img src={ErrorLogo} alt="logo" />
                    <p className="error-msg">{unautorizedMsg}</p>
                  </section>
                </div>
              ) : steps?.length > 0 ? (
                <div className='row'>
                  <div className='col-lg-2'>
                    <Stepper activeStep={activeStep} orientation="vertical">
                      {steps.map((label, i): any => (
                        <Step key={i}>
                          <StepLabel>
                            {label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </div>
                  <div className='col-lg-10'>
                    {(!!authState.userInfo.crmAccessKey && !!customLeadForm) ? (
                      crmFieldHandler()
                    ) : (!!authState.userInfo.secretKey && !customLeadForm) && (
                      fieldsHandler()
                    )}
                  </div>
                </div>
              ) : (
                <div className='col-lg-12'>
                  <section className='form-container' style={{ minHeight: 350, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p className="fw-bold">Not Found!</p>
                  </section>
                </div>
              )}
            </div>
          </form>
        </FormProvider>
      </main>
    </div>
  )
}

export default TestApplicationForm