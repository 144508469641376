import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";

const Input = ({
  name,
  control,
  label,
  errors = {},
  type = 'text',
  size = 'small',
  readOnly = false,
  rules = {},
  ...rest
}: any) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <TextField
          {...field}
          {...rest}
          type={type}
          size={size}
          className='pb-2'
          fullWidth
          inputProps={{ readOnly }}
          label={label}
          error={!!errors[name]}
          helperText={errors[name] ? errors[name].message : ' '}
        />
      )}
    />
  )
}

export default Input