import { createTheme, ThemeProvider } from '@mui/material/styles';
import MuiButton from '@mui/material/Button';

const theme = createTheme({
  palette: {
    custom: {
      main: '#F49F0A',
      contrastText: '#fff',
    },
  },
});

declare module '@mui/material/styles' {
  interface Palette {
    custom: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    custom?: PaletteOptions['primary'];
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    custom: true;
  }
}


const Button = ({
  color = 'custom',
  fullWidth = false,
  size = 'normal',
  variant = 'contained',
  type = 'button',
  children,
  disabled = false,
  ...rest
}: any) => {
  return (
    <ThemeProvider theme={theme}>
      <MuiButton
        {...rest}
        type={type}
        color={color}
        variant={variant}
        fullWidth={fullWidth}
        size={size}
        disableElevation
        disabled={disabled}
      >
        {children}
      </MuiButton>
    </ThemeProvider>
  )
}

export default Button