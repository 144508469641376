import { useState, useContext } from 'react'
import Pagination from "../../../shared/Pagination";

import {
  IconButton, Skeleton
} from '@mui/material'
import {
  EditOutlined as EditIcon
} from '@mui/icons-material'
import { useForm } from "react-hook-form";
import { useQuery } from "react-query"
import { Button } from "../../../form-controls";
import { useNavigate } from "react-router-dom";
import { FetchContext } from "../../../../context/FetchContext"
import apiResources from '../../../../api/api.constants'

const Admission = () => {

  const [page, setPage] = useState(1)
  const {
    register,
    handleSubmit,
  } = useForm()

  const onSubmitFilter = (data: any) => console.log(data)
  const navigate = useNavigate()

  const { authFetch }: any = useContext(FetchContext)

  const getList = async (page: number = 1) => {
    const options = {
      filter: {
        "role": "student"
      },
      range: {
        "page": page,
        "pageSize": 10
      }
    }
    const { data: response } = await authFetch.post(apiResources.getUsers, options)
    return response
  }

  const list = useQuery(['applications', page], () => getList(page))

  return (
    <section className="listing-container mt-3">
      <form onSubmit={handleSubmit(onSubmitFilter)} autoComplete="off">
        <div className="listing-actions">
          <input className="form-control" {...register('searchSchool')} placeholder="Search for a student..." />
          <Button type='submit' className='ms-2'>Submit</Button>
        </div>
      </form>

      <div className="table-responsive">
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Status</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {list.isLoading ? (
              <tr>
                <td><Skeleton variant='text' /></td>
                <td><Skeleton variant='text' /></td>
                <td><Skeleton variant='text' /></td>
                <td className='d-flex'>
                  <Skeleton variant='circular' width={24} height={24} />
                </td>
              </tr>
            ) : list.data?.data?.length ? (
              list.data?.data?.map((item: any) => (
                <tr key={item.id}>
                  <td>{item.firstName} {item.lastName}</td>
                  <td>{item.email}</td>
                  <td className='text-capitalize'>{item.status}</td>
                  <td>
                    <IconButton title='Edit' size="small" onClick={() => navigate('/test/admissions/details', { state: item })}>
                      <EditIcon fontSize='small' />
                    </IconButton>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5}>No Records Found...</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Pagination totalPages={list.data?.pageCount} onChange={setPage} />
    </section>
  )
};

export default Admission;
