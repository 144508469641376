import { useEffect, useContext } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { Input, Select, Button } from '../../../form-controls';
import { FetchContext } from "../../../../context/FetchContext"
import apiResources from "../../../../api/api.constants"

const UserForm = ({ open, handleClose, initialData }: any) => {
  const { authFetch }: any = useContext(FetchContext)

  const schema = yup.object({
    firstName: yup.string().required('First name is required.'),
    lastName: yup.string().required('Last name is required.'),
    email: yup.string().email().required('Email is required.'),
    phone: yup.string().required('Mobile is required.'),
    // role: yup.string().required('Role is required.'),
  })
  
  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    // role: 'super admin'
  }

  // const roles = [
  //   { label: 'Super Admin', value: 'super admin' },
  //   { label: 'School Admin', value: 'school admin' },
  // ]

  const { handleSubmit, control, setValue, formState: { errors }} = useForm({ defaultValues, resolver: yupResolver(schema) })

  useEffect(() => {
    if (initialData.id) {
      setValue('firstName', initialData.firstName)
      setValue('lastName', initialData.lastName)
      setValue('email', initialData.email)
      setValue('phone', initialData.phone)
    }
  }, [])

  const onSubmit = async (formData: any) => {
    let payload = {}
    const url = initialData.id ? `${apiResources.updateUser}/${initialData.id}` : apiResources.createUser

    if (initialData.id) {
      payload = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        phone: formData.phone,
        // status: formData.status ? 'active' : 'in-active'
      }
    } else {
      payload = {
        ...formData,
        role: 'admin'
      }
    }

    const { data } = await authFetch.post(url, payload)
    if(data.success) {
      handleClose('updated')
    }
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} sx={{ '& .MuiPaper-root': { borderRadius: 3 } }}>
        <DialogTitle sx={{ marginLeft: '12px', fontWeight: 'bold' }}>New User</DialogTitle>
        <DialogContent sx={{  paddingTop: '10px !important', paddingBottom: 0 }}>
          <form id='user-form' onSubmit={handleSubmit(onSubmit)}>
            <div className='container'>
              <div className='row gap-0'>
                <div className='col-md-6'>
                  <Input autoFocus control={control} errors={errors} name='firstName' label='First Name' />
                </div>
                <div className='col-md-6'>
                  <Input control={control} errors={errors} name='lastName' label='Last Name' />
                </div>
                <div className='col-md-12'>
                  <Input disabled={!!initialData.id} control={control} errors={errors} name='email' label='Email' />
                </div>
                <div className='col-md-12'>
                  <Input control={control} errors={errors} name='phone' label='Mobile' />
                </div>
                {/* <div className='col-md-12'>
                  <Select control={control} errors={errors} name='role' label='Role' options={roles} />
                </div> */}
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions sx={{ px: '36px', pb: 3 }}>
          <Button color='inherit' onClick={handleClose}>Cancel</Button>
          <Button type='submit' form='user-form'>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default UserForm
