import {
  TextField,
  Autocomplete
} from '@mui/material'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import { Controller } from 'react-hook-form';

const GeoAutocomplete = ({
  control,
  errors,
  name,
  label,
  rules = {},
  setValue,
  ...rest
}: any) => {

  const handleSelect = async (e: any) => {
    const address = e.target.value
    if (!address) {
      setValue('address', '')
      setValue('latitude', '')
      setValue('longitude', '')
      return
    }
    setValue('address', address)
    const results = await geocodeByAddress(address)
    const { lat, lng } = await getLatLng(results[0])
    setValue('latitude', lat)
    setValue('longitude', lng)
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, field: { value } }) => (
        <PlacesAutocomplete
          {...field}
          value={value}
        >
          {({ getInputProps, suggestions }: any) => (
            <Autocomplete
              freeSolo
              {...rest}
              disablePortal
              value={value}
              options={suggestions.map((suggestion: any) => suggestion.description)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...getInputProps()}
                  label={label}
                  onBlurCapture={handleSelect}
                  error={!!errors[name]}
                  helperText={errors[name]?.message || ' '}
                />
              )}
            />
          )}
        </PlacesAutocomplete>
      )}
    />
  )
};

export default GeoAutocomplete;
