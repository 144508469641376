import { useContext, useEffect } from "react";
import { Routes, Route, Outlet, Navigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SignIn from './components/Auth/SignIn';
import ForgotPassword from './components/Auth/ForgotPassword';
import SetupPassword from './components/Auth/SetupPassword';
import Layout from './components/panel/Layout';
import {
  Dashboard,
  Users,
  Clients
} from './components/panel/screens';
import SlugIndex from "./components/shared/SlugIndex";
import { AuthContext } from "./context/AuthContext";

function SuperAdminRoute() {
  const { authState: { userInfo: { role } } }: any = useContext(AuthContext)

  return role === 'super-admin'
  ? <Outlet/>
  : <Navigate to='/'/>
}

function App() {
  const location = useLocation();
  const { logout }: any = useContext(AuthContext);

  useEffect(()=>{
    let slug = location.pathname?.split("/")[1];
    if(slug){
      logout()
    }
  }, [])

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="activate-account" element={<SetupPassword />} />
        <Route element={<SuperAdminRoute/>}>
          <Route path="dashboard" element={<Layout pageTitle='Dashboard' ><Dashboard /></Layout>} />
          <Route path="clients" element={<Layout pageTitle='Clients' ><Clients /></Layout>} />
          <Route path="users" element={<Layout pageTitle='Users' ><Users /></Layout>} />
        </Route>
        <Route path={`${location.pathname?.split("/")[1]}/*`} element={<SlugIndex />} />
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
      />
    </div>
  );
}

export default App;
