import { useState, createContext } from 'react';
import { useNavigate } from 'react-router';

interface AuthState {
  token: any;
  userInfo: any;
  authId: any;
  erpToken: any;
  erpDbToken: any;
  erpSubdomain: any;
  registrationOptions: any;
  crmToken?: any;
}

interface AuthContextInterface {
  authState: AuthState;
  setAuthInfo: Function;
  setAuthId: Function;
  setErpAuthInfo: Function;
  isAuthenticated: Function;
  logout: Function;
  setRegistrationOptions: Function;
  setCRMAuthInfo: Function;
}

const AuthContext = createContext<AuthContextInterface | null>(null);
const { Provider } = AuthContext;

const AuthProvider = ({ children }: any) => {
  const navigate = useNavigate()

  const token = localStorage.getItem('admissions@scp@token')
  const userInfo = localStorage.getItem('admissions@scp@userInfo')
  const authId = localStorage.getItem('admissions@scp@authId')
  const erpToken = localStorage.getItem('admissions@scp@erpToken')
  const erpDbToken = localStorage.getItem('admissions@scp@erpDbToken')
  const erpSubdomain = localStorage.getItem('admissions@scp@erpSubdomain')
  const crmToken = localStorage.getItem('admissions@scp@crmToken');

  const [authState, setAuthState] = useState({
    token,
    userInfo: userInfo ? JSON.parse(userInfo) : {},
    authId,
    erpToken,
    erpDbToken,
    erpSubdomain,
    registrationOptions: null,
    crmToken
  });

  const setAuthInfo = ({ accessToken, data }: any) => {
    localStorage.setItem('admissions@scp@token', accessToken)
    localStorage.setItem('admissions@scp@userInfo', JSON.stringify(data))
    setAuthState(state => ({ ...state, token: accessToken, userInfo: data }))
  }

  const setErpAuthInfo = ({ token, dbToken, subdomain }: any) => {
    localStorage.setItem('admissions@scp@erpToken', token)
    localStorage.setItem('admissions@scp@erpDbToken', dbToken)
    localStorage.setItem('admissions@scp@erpSubdomain', subdomain)
    setAuthState(state => ({
      ...state,
      erpToken: token,
      erpDbToken: dbToken,
      erpSubdomain: subdomain
    }))
  }

  const setCRMAuthInfo = (token: string) => {
    if(!token || token == undefined){
      return;
    }
    localStorage.setItem('admissions@scp@crmToken', token);
    setAuthState(state => ({
      ...state,
      crmToken: token
    }))
  }

  const setAuthId = (authId: any) => {
    localStorage.setItem('admissions@scp@authId', authId)
    setAuthState(state => ({ ...state, authId }))
  }

  const isAuthenticated = () => {
    return authState.token ? true : false
  }

  const logout = (redirectTo = "") => {
    resetState();
    if(!!redirectTo){
      navigate(redirectTo)
    }
  }

  const resetState = () => {
    localStorage.removeItem('admissions@scp@token')
    localStorage.removeItem('admissions@scp@userInfo')
    localStorage.removeItem('admissions@scp@erpToken')
    localStorage.removeItem('admissions@scp@erpDbToken')
    localStorage.removeItem('admissions@scp@erpSubdomain')
    localStorage.removeItem('admissions@scp@crmToken')

    setAuthState({
      token: null,
      userInfo: {},
      authId: null,
      erpToken: null,
      erpDbToken: null,
      erpSubdomain: null,
      registrationOptions: null,
      crmToken: ""
    })
  }

  const setRegistrationOptions = (options = null) => {
    return setAuthState(state => ({ ...state, registrationOptions: options }));
  }

  return (
    <Provider
      value={{
        authState,
        setAuthInfo,
        setAuthId,
        setErpAuthInfo,
        isAuthenticated,
        logout,
        setRegistrationOptions,
        setCRMAuthInfo
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };