import { Input, Button } from '../../form-controls';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Link } from 'react-router-dom';

import '../style.css'

import AppLogo from '../../../assets/images/schoolzprologo-dark.png'

const schema = yup.object({
  email: yup.string().email('Invalid Email').required('Email is required.'),
}).required();

const defaultValues = {
  email: '',
}

const ForgotPassword = () => {

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues: defaultValues, mode: 'all', resolver: yupResolver(schema) })

  const onSubmit = (data: any) => console.log(data);

  return (
    <div className="auth__container">
      <div className="auth__bg d-none d-lg-block"></div>
      <div className="auth__content p-3 p-lg-0">
        <figure className="auth__logo">
          <img src={AppLogo} alt="logo" />
        </figure>
        <form className='auth__form mt-5' onSubmit={handleSubmit(onSubmit)}>
          <h1 className='mb-4 fw-normal'>Forgot Password?</h1>
          <Input
            name='email'
            label='Email Address'
            size='large'
            control={control}
            errors={errors}
          />
          <Button
            type='submit'
            fullWidth
            size='large'
          >
            Submit
          </Button>
          <div className='text-center'>Remember your password? <Link to='/' className='link'>Login</Link></div>
        </form>
      </div>
    </div>
  )
}

export default ForgotPassword