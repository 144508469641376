import { Controller } from "react-hook-form";
import {
  FormLabel,
  FormControl,
  FormControlLabel,
  RadioGroup as MuiRadioGroup,
  Radio,
  FormHelperText
} from "@mui/material";

const RadioGroup = ({
  name,
  control,
  label,
  errors = {},
  row = true,
  required = false,
  disabled = false,
  options
}: any) => {

  const createOptions = () => {
    return options.map((item: any) => (
      <FormControlLabel
        key={item.value}
        value={item.value}
        control={<Radio disabled={disabled} size="small" />}
        label={item.label}
      />
    ))
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl error={!!errors[name]}>
          <FormLabel required={required} id={label}>{label}</FormLabel>
          <MuiRadioGroup
            {...field}
            row={row}
            aria-labelledby={label}
          >
            {createOptions()}
          </MuiRadioGroup>
          <FormHelperText>{errors[name] ? errors[name].message : ' '}</FormHelperText>
        </FormControl>
      )}
    />
  )
}

export default RadioGroup