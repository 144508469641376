import { useContext, useEffect } from 'react'
import { toast } from "react-toastify";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup'
import {
  Input,
  Button,
  Switch,
  File,
  GeoAutocomplete
} from '../../../form-controls';
import { FetchContext } from "../../../../context/FetchContext"
import apiResources from "../../../../api/api.constants"

const ClientForm = ({ open, handleClose, initialData }: any) => {
  const { authFetch }: any = useContext(FetchContext)

  const schema = yup.object({
    name: yup.string().required('Organization name is required.'),
    slug: yup.string().required('Slug is required.'),
    address: yup.string().required('Address is required.'),
    firstName: !initialData.id ? yup.string().required('First name is required.') : yup.string(),
    lastName: !initialData.id ? yup.string().required('Last name is required.') : yup.string(),
    email: !initialData.id ? yup.string().email('Invalid Email').required('Email is required.') : yup.string(),
    phone: !initialData.id ? yup.string().required('Mobile is required.') : yup.string(),
    logo: yup.string().required('Logo is required.'),
    longitude: yup.string().required(),
    latitude: yup.string().required()
  })

  const defaultValues = {
    name: '',
    slug: '',
    address: '',
    longitude: '',
    latitude: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    logo: '',
    colorCode: '#0A62A7',
    status: true,
    secretKey: '',
    crmAccessKey: '',
    crmClientID: ''
  }

  const { handleSubmit, control, register, setValue, resetField, formState: { errors } } = useForm({ defaultValues, mode: 'all', resolver: yupResolver(schema) })

  const onSubmit = async (formData: any) => {
    // console.log('formData', formData)
    let payload = {}
    const url = initialData.id ? `${apiResources.updateClient}/${initialData.id}` : apiResources.createClient

    if (initialData.id) {
      payload = {
        name: formData.name,
        address: formData.address,
        longitude: formData.longitude,
        latitude: formData.latitude,
        logo: formData.logo,
        colorCode: formData.colorCode,
        status: formData.status ? 'active' : 'in-active',
        secretKey: formData.secretKey || "",
        crmClientID: formData.crmClientID || "",
        crmAccessKey: formData.crmAccessKey || ""
      }
    } else {
      payload = {
        ...formData,
        status: 'active'
      }
    }

    try {
      const { data } = await authFetch.post(url, payload)
      if(data.success) {
        toast.success(`Client ${initialData.id ? 'updated' : 'added'} successfully.`)
        handleClose('updated');
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again later.")
    }
  }

  useEffect(() => {
    if (initialData.id) {
      setValue('name', initialData.name)
      setValue('slug', initialData.slug)
      setValue('address', initialData.address)
      setValue('longitude', initialData.longitude)
      setValue('latitude', initialData.latitude)
      setValue('logo', initialData.logo)
      setValue('colorCode', initialData.colorCode)
      setValue('status', initialData.status === 'active' ? true : false)
      setValue('secretKey', initialData.secretKey)
      setValue('crmAccessKey', initialData.crmAccessKey)
      setValue('crmClientID', initialData.crmClientID)
    }
  }, [])

  return (
    <div>
      <Dialog open={open} onClose={handleClose} sx={{ '& .MuiPaper-root': { borderRadius: 3 } }}>
        <DialogTitle sx={{ marginLeft: '12px', fontWeight: 'bold', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>{initialData.id ? 'Update Client' : 'New Client'}</span>
          {initialData.id && <Switch name='status' control={control} />}
        </DialogTitle>
        <DialogContent sx={{ paddingTop: '10px !important', paddingBottom: 0 }}>
          <form id='user-form' onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className='container'>
              <div className='row gap-0'>
                <div className='col-md-6'>
                  <Input control={control} errors={errors} name='name' label='Organization Name' required />
                </div>
                <div className='col-md-6'>
                  <Input disabled={!!initialData.id} control={control} errors={errors} name='slug' label='Slug' required />
                </div>
                <div className='col-md-12'>
                  <GeoAutocomplete control={control} errors={errors} setValue={setValue} name='address' label='Address' />
                </div>
                <div className='col-md-9'>
                  <File control={control} errors={errors} reset={resetField} name='logo' label='Logo' />
                </div>
                <div className='col-md-3'>
                  <label htmlFor="colorCode" className="form-label">Color Code</label>
                  <input type="color" {...register('colorCode')} className="form-control form-control-color w-100" title="Choose your color code" />
                  <small>&nbsp;</small>
                </div>
                <div>
                  <label className="form-label">Integrate with ERP</label>
                  <div className='col-12'>
                    <Input control={control} errors={errors} name='secretKey' label='Secret Key' />
                  </div>
                </div>
                <>
                  <label className="form-label">Integrate with CRM</label>
                  <div className='col-md-6'>
                    <Input control={control} errors={errors} name='crmAccessKey' label='Access Key' />
                  </div>
                  <div className='col-md-6'>
                    <Input control={control} errors={errors} name='crmClientID' label='Client Id' />
                  </div>
                </>
                {!initialData.id && (
                  <>
                    <label className="form-label">User Details</label>
                    <div className='col-md-6'>
                      <Input control={control} errors={errors} name='firstName' label='First Name' required />
                    </div>
                    <div className='col-md-6'>
                      <Input control={control} errors={errors} name='lastName' label='Last Name' required />
                    </div>
                    <div className='col-md-6'>
                      <Input control={control} errors={errors} name='email' label='Email' required />
                    </div>
                    <div className='col-md-6'>
                      <Input control={control} errors={errors} name='phone' label='Mobile' required />
                    </div>
                  </>
                )}
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions sx={{ px: '36px', pb: 3 }}>
          <Button color='inherit' onClick={handleClose}>Cancel</Button>
          <Button type='submit' form='user-form'>{initialData.id ? 'Update' : 'Submit'}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ClientForm
