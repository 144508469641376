import { Skeleton } from '@mui/material';
import ReactPaginate from 'react-paginate';

const Pagination = ({ totalPages, onChange, loading }: any) => {
  return (
    <div style={{ background: '#F8FAFB', marginBottom: 10, padding: 10, borderRadius: 8 }}>
      {loading ? (
        <div className='d-flex gap-2'>
          <Skeleton className='ms-auto' variant='text' width={55} height={40} />
          <Skeleton variant='text' width={24} height={40} />
          <Skeleton variant='text' width={55} height={40} />
        </div>
      ) : (
        <nav className='ml-auto'>
          <ReactPaginate
            pageCount={totalPages}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            onPageChange={({ selected }) => onChange(selected + 1)}
            containerClassName="pagination pagination-sm justify-content-end"
            activeClassName="active"
            pageLinkClassName="page-link"
            breakLinkClassName="page-link"
            nextLinkClassName="page-link"
            previousLinkClassName="page-link"
            pageClassName="page-item"
            breakClassName="page-item"
            nextClassName="page-item"
            previousClassName="page-item"
            previousLabel={'Previous'}
            nextLabel={'Next'}
          />
        </nav>
      )}
    </div>
  )
}

export default Pagination;