import { Controller } from "react-hook-form";
import {
  FormControl,
  Select as MuiSelect,
  MenuItem,
  InputLabel,
  FormHelperText
} from "@mui/material";

const Select = ({
  name,
  control,
  label,
  errors = {},
  rules = {},
  required = false,
  disabled = false,
  readOnly = false,
  multiple = false,
  options = []
}: any) => {

  const createOptions = () => {
    return options.map((item: any) => (
      <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
    ))
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <FormControl
          className='custom-select pb-2'
          fullWidth
          error={!!errors[name]}
          required={required}
          disabled={disabled}
        >
          <InputLabel id={label}>{label}</InputLabel>
          <MuiSelect
            {...field}
            multiple={multiple}
            labelId={label}
            label={label}
            size="small"
            inputProps={{ readOnly }}
          >
            {createOptions()}
          </MuiSelect>
          <FormHelperText>{errors[name] ? errors[name].message : ' '}</FormHelperText>
        </FormControl>
      )}
    />
  )
}

export default Select