import { Controller } from "react-hook-form";
import mediaFetch from "../../../api/mediaFetch";

const File = ({
  name,
  control,
  reset,
  label = '',
  errors = {}
}: any) => {

  const handleFileUpload = async (e: any) => {
    const files = e.target.files
    if (files.length) {
      const formData = new FormData();
      Array.from(files).forEach((file: any, i: number) => {
        formData.append("file", files[i], file.name);
      })
      
      try {
        const { data: res } = await  mediaFetch.post(process.env.REACT_APP_MEDIA_API_URL!, formData)
        return `https://${res.attributes?.url}`
      } catch (err) {
        console.log('Something went wrong. Please try again later.')
        return err
      } 
    }
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, field: { onChange} }) => (
        <div className="form-group mb-2">
          <label htmlFor={name} className="form-label">{label}</label>
          <input
            {...field}
            className="form-control error"
            type="file"
            id={name}
            onChange={(e) => handleFileUpload(e).then(data => onChange(data)).catch(err => console.log('ree'))}
            value={undefined}
          />
          <small style={{ color: errors[name]?.message ? '#d32f2f' : '', margin: '4px 14px 0' }}>{errors[name]?.message}</small>
        </div>
      )}
    />
  )
}

export default File