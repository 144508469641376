import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom'

import {
  Box,
  AppBar as MuiAppBar,
  Toolbar,
  IconButton,
  Typography,
  Badge,
  useScrollTrigger,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Chip
} from '@mui/material';

import {
  Menu as MenuIcon,
  Notifications as NotificationsIcon,
  Logout as LogoutIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material'
import { AuthContext } from '../../../context/AuthContext';

function ElevationScroll(props: any) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}


const AppBar = ({ pageTitle, drawerWidth, handleDrawerToggle }: any) => {
  const { logout, authState: { userInfo } }: any = useContext(AuthContext)
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const location = useLocation()

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const slug = location.pathname.split('/')[1]
  const redirectTo = userInfo.role !== 'super-admin' ? `/${slug}/signin` : '/'

  return (
    <ElevationScroll>
      <MuiAppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          background: 'rgba(255,255,255,0.6)',
          color: '#222',
          borderBottom: '1px solid #E7EBF0',
          backdropFilter: 'blur(20px)'
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }} noWrap component="div">
            {pageTitle}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box>
            {/* <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={17} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            <Chip
              className='ms-2'
              sx={{ background: '#1b6e67 linear-gradient(to bottom left, #1b6e67, #1e1c8c)', '&:hover': { background: '#1b6e67 linear-gradient(to bottom left, #1b6e67, #1e1c8c)' } }}
              label={userInfo.firstName}
              onClick={handleOpenUserMenu}
              onDelete={handleOpenUserMenu}
              deleteIcon={<ExpandMoreIcon />}
              color="warning"
            />
            <Menu
              sx={{ mt: '35px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem dense onClick={() => logout(redirectTo)}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Logout</ListItemText>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </MuiAppBar>
    </ElevationScroll>
  )
}

export default AppBar