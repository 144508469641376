import { Input, Button } from '../../form-controls';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import '../style.css'

import AppLogo from '../../../assets/images/schoolzprologo-dark.png'

const schema = yup.object({
  password: yup.string().required('Password is required.').min(8, 'Password must be at least 8 characters'),
  cnfPassword: yup.string().required('Confirm Password is required.').oneOf([yup.ref('password')], 'Passwords does not match')
}).required();

const defaultValues = {
  password: '',
  cnfPassword: '',
}

const SetupPassword = () => {

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues: defaultValues, mode: 'all', resolver: yupResolver(schema) })

  const onSubmit = (data: any) => console.log(data);

  return (
    <div className="auth__container">
      <div className="auth__bg d-none d-lg-block"></div>
      <div className="auth__content p-3 p-lg-0">
        <figure className="auth__logo">
          <img src={AppLogo} alt="logo" />
        </figure>
        <form className='auth__form mt-5' onSubmit={handleSubmit(onSubmit)}>
          <h1 className='mb-4 fw-normal'>Activate Account</h1>
          <Input
            type='password'
            name='password'
            label='Password'
            size='large'
            control={control}
            errors={errors}
          />
          <Input
            type='password'
            name='cnfPassword'
            label='Confirm Password'
            size='large'
            control={control}
            errors={errors}
          />
          <Button
            type='submit'
            fullWidth
            size='large'
          >
            Submit
          </Button>
        </form>
      </div>
    </div>
  )
}

export default SetupPassword