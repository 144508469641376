import { createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom' 
import axios, { AxiosInstance } from 'axios';
import { AuthContext } from './AuthContext'

interface FetchContextInterface {
  authFetch: AxiosInstance,
  publicFetch: AxiosInstance,
  authErpFetch: AxiosInstance,
  publicErpFetch: AxiosInstance,
  authCrmFetch: AxiosInstance,
  publicCrmFetch: AxiosInstance,
}

const FetchContext = createContext<FetchContextInterface | null>(null);
const { Provider } = FetchContext;

const FetchProvider = ({ children }: any) => {
  const auth = useContext(AuthContext);
  const location = useLocation()
  
  const authFetch = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  });

  authFetch.interceptors.request.use(config => {
    config.headers = {
      Authorization: `Bearer ${auth?.authState?.token}`,
    }
    return config
  }, err => Promise.reject(err))
  
  const authErpFetch = axios.create({
    baseURL: process.env.REACT_APP_ERP_API_URL
  });

  authErpFetch.interceptors.request.use(config => {
    config.headers = {
      Authorization: `Bearer ${auth?.authState?.erpToken}`,
      dbtoken: auth?.authState?.erpDbToken,
      subdomain: auth?.authState?.erpSubdomain
    }
    return config
  }, err => Promise.reject(err))

  const publicFetch = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: location.pathname.split('/')[1]
    ? { Authorization: auth?.authState?.token }
    : { AuthId: 'schoolzpro_admissions' }
  });

  const publicErpFetch = axios.create({
    baseURL: process.env.REACT_APP_ERP_API_URL,
    headers: {
      subdomain: auth?.authState?.erpSubdomain
    }
  });

  publicErpFetch.interceptors.request.use(config => {
    config.data = {
      ...config.data,
      fcm_id: process.env.REACT_APP_ERP_FCM_ID,
      device_id: process.env.REACT_APP_ERP_DEVICE_ID,
      device_type: 'web',
      locale: 'en'
    }
    return config
  }, err => Promise.reject(err))

  const authCrmFetch = axios.create({
    baseURL: process.env.REACT_APP_CRM_API_URL
  });

  authCrmFetch.interceptors.request.use(config => {
    config.headers = {
      Authorization: `Bearer ${auth?.authState?.crmToken}`,
      clientid: "RFX6U7NZ5ONV0SY6"
    }
    return config
  }, err => Promise.reject(err))

  const publicCrmFetch = axios.create({
    baseURL: process.env.REACT_APP_CRM_API_URL,
    // headers: {
    //   authorization: `Basic ZXNzZW5jZXNvZnR3YXJlczoyaEA5ISpxampIbiVn`
    // }
  });

  publicCrmFetch.interceptors.request.use(config => {
    config.headers = {
      clientid: "RFX6U7NZ5ONV0SY6"
    }
    config.data = {
      ...config.data,
      // fcm_id: process.env.REACT_APP_ERP_FCM_ID,
      // device_id: process.env.REACT_APP_ERP_DEVICE_ID,
      // device_type: 'web',
      locale: 'en'
    }
    return config
  }, err => Promise.reject(err))

  return (
    <Provider
      value={{
        authFetch,
        publicFetch,
        authErpFetch,
        publicErpFetch,
        authCrmFetch,
        publicCrmFetch
      }}
    >
      {children}
    </Provider>
  );
};

export { FetchContext, FetchProvider };
