import React, { useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Drawer,
  Toolbar,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@mui/material'
import AppLogo from '../../../assets/images/schoolzprologo.png'

import {
  HomeOutlined as HomeOutlinedIcon,
  SchoolOutlined as SchoolOutlinedIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  SettingsOutlined as SettingsOutlinedIcon,
  ArticleOutlined as ArticleOutlinedIcon,
  PersonOutline as PersonOutlineIcon
} from '@mui/icons-material'
import { AuthContext } from '../../../context/AuthContext';

const routes = [
  {
    title: 'Dashboard',
    icon: <HomeOutlinedIcon />,
    path: '/dashboard',
    allowedRoles: ['super-admin', 'admin', 'student']
  },
  {
    title: 'My Application',
    icon: <ArticleOutlinedIcon />,
    path: '/my-application',
    allowedRoles: ['student']
  },
  {
    title: 'Admissions',
    icon: <ArticleOutlinedIcon />,
    path: '/admissions',
    allowedRoles: ['admin']
  },
  {
    title: 'Clients',
    icon: <PersonOutlineIcon />,
    path: '/clients',
    allowedRoles: ['super-admin']
  },
  {
    title: 'Academics',
    icon: <SchoolOutlinedIcon />,
    allowedRoles: ['admin'],
    childRoutes: [
      {
        title: 'Class',
        path: '/class'
      },
      {
        title: 'Campus',
        path: '/campus'
      },
      {
        title: 'Sessions',
        path: '/sessions'
      },
    ]
  },
  {
    title: 'Settings',
    icon: <SettingsOutlinedIcon />,
    allowedRoles: ['admin'],
    childRoutes: [
      {
        title: 'Points',
        path: '/points'
      },
      {
        title: 'Registration Fee',
        path: '/registration-fee'
      },
    ]
  },
  {
    title: 'Users',
    icon: <PersonOutlineIcon />,
    path: '/users',
    allowedRoles: ['admin']
  },
]

const NavItem = ({ title, path, icon = '', role }: any) => {
  const location = useLocation()
  const navigate = useNavigate()
  const slug = location.pathname.split('/')[1]
  
  return (
    <ListItemButton component="li" className={`sidebar__item ${role !== 'super-admin' ? location.pathname === `/${slug}${path}` ? 'active' : '' : location.pathname === path ? 'active' : ''}`} onClick={() => navigate(role !== 'super-admin' ? `/${slug}${path}` : path)} >
      {icon && (
        <ListItemIcon sx={{ mr: '8px', minWidth: 'unset', color: 'inherit' }}>
          {icon}
        </ListItemIcon>
      )}
      <ListItemText primary={title} />
    </ListItemButton>
  )
}

const NestedNavItem = ({ title, icon, items, role }: any) => {

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <ListItemButton component="li" className={`sidebar__item ${menuOpen ? 'active' : ''}`} onClick={() => setMenuOpen(!menuOpen)}>
        <ListItemIcon sx={{ mr: '8px', minWidth: 'unset', color: 'inherit' }}>
          {icon}
        </ListItemIcon>
        <ListItemText primary={title} />
        {menuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItemButton>
      <Collapse className='sidebar__collapse' in={menuOpen} timeout="auto" unmountOnExit>
        <List disablePadding>
          {items.map((item: any) => (
            <NavItem key={item.title} title={item.title} path={item.path} role={role} />
          ))}
        </List>
      </Collapse>
    </>
  )
}

const Sidebar = ({
  drawerWidth,
  mobileOpen,
  handleDrawerToggle,
}: any) => {

  const { authState: { userInfo: { role, secretKey} }}: any = useContext(AuthContext)

  const drawer = (
    <div className='sidebar'>
      <Toolbar className='mb-4'>
        <img src={AppLogo} alt="Logo" />
      </Toolbar>
      <List>
        {routes.map(({ title, icon, path, childRoutes, allowedRoles }) => (
          !(secretKey && title === 'Users') ? (
            <React.Fragment key={title}>
              {allowedRoles.includes(role) && (
                !childRoutes ? (
                  <NavItem title={title} icon={icon} path={path} role={role} />
                ) : (
                  <NestedNavItem title={title} icon={icon} items={childRoutes} role={role} />
                )
              )}
            </React.Fragment>
          ) : null
        ))}
      </List>
    </div>
  )

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
    >
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  )
}

export default Sidebar