const apiResources = {
  login: '/v1/auth/sign-in',
  loginWithErp: '/v1/user/login',
  getAuthIdBySlug: '/v1/auth/client/generate-token',
  getErpClientToken: '/v1/user/integration/login',
  getCrmClientToken: '/v1/user/client/authenticate',

  sendDataToErp: '/v1/student/registration',

  getClients: '/v1/clients/list',
  createClient: '/v1/clients/create',
  updateClient: '/v1/clients/update',
  deleteClient: '/v1/clients/delete',

  getUsers: '/v1/users/list',
  createUser: '/v1/users/create',
  updateUser: '/v1/users/update',
  deleteUser: '/v1/users/delete',

  getClasses: '/v1/class/list',
  getErpClasses: '/v1/academic/class?locale=en&page_length=0',
  createClass: '/v1/class/create',
  updateClass: '/v1/class/update',
  deleteClass: '/v1/class/delete',
  deleteFees: '/v1/class/delete/fees',

  getSessions: '/v1/session/list',
  getErpSessions: '/v1/academic/session?locale=en&page_length=0',
  createSession: '/v1/session/create',
  updateSession: '/v1/session/update',
  deleteSession: '/v1/session/delete',

  getCampuses: '/v1/campus/list',
  getErpCampuses: '/v1/academic/campus?locale=en&page_length=0',
  createCampus: '/v1/campus/create',
  updateCampus: '/v1/campus/update',
  deleteCampus: '/v1/campus/delete',

  getPoints: '/v1/points/list',
  createPoint: '/v1/points/create',
  updatePoint: '/v1/points/update',
  deletePoint: '/v1/points/delete',
  
  getErpConfiguration: '/v1/settings/configuration?locale=en',

  //Crm Master APIs
  leadTemplate: '/v1/setting/2/template/list',
  leadTemplateDetail: '/v1/setting/2/template/52?locale=en',
  getStageList: '/v1/master/stage/list',
  getContactList: '/v1/lead/list',
  getAccountList: '/v1/account/list',
  getLeadList: '/v1/master/category/list',
  getSourceList: '/v1/master/source/list',
  getQueryPriority: '/v1/master/query-priorities/list',
  getCRMSessionList: '/v1/master/academic-session/list',
  getCRMSchoolList: '/v1/master/school/list',
  getCRMClassList: '/v1/master/class/list',
  createLead: '/v1/lead/query'
}

export default apiResources