import { useContext } from 'react'
import {
  Routes,
  Route,
  Outlet,
  Navigate
} from 'react-router-dom'
import TestApplicationForm from '../../ApplicationForms/TestApplicationForm'
import {
  ClassMaster,
  CampusMaster,
  SessionMaster,
  Dashboard,
  Users,
  Admissions,
  AdmissionDetails,
  MyApplication,
  PointsSetup,
  RegistrationFeeSetup,
  Clients,
} from '../../panel/screens'
import SuccessfulSubmission from '../../SuccessfulSubmission'
import Layout from '../../panel/Layout'
import SignIn from '../../Auth/SignIn'
import { AuthContext } from '../../../context/AuthContext'

function SchoolAdminRoute() {
  const { authState: { userInfo: { role } } }: any = useContext(AuthContext)

  return role === 'admin'
    ? <Outlet />
    : <Navigate to='/test/signin' />
}

function StudentRoute() {
  const { authState: { userInfo: { role } } }: any = useContext(AuthContext)

  return role === 'student'
    ? <Outlet />
    : <Navigate to='/test/signin' />
}

function ProtectedRoute() {
  const { isAuthenticated }: any = useContext(AuthContext)

  return isAuthenticated()
    ? <Outlet />
    : <Navigate to='/test/signin' />
}

const SlugIndex = () => {
  return (
    <Routes>
      <Route path="" element={<TestApplicationForm />} />
      <Route path="signin" element={<SignIn />} />
      <Route path="confirmation" element={<SuccessfulSubmission />} />
      <Route element={<ProtectedRoute />}>
        <Route path="dashboard" element={<Layout pageTitle='Dashboard'><Dashboard /></Layout>} />
      </Route>
      <Route element={<SchoolAdminRoute />}>
        <Route path="users" element={<Layout pageTitle='Users' ><Users /></Layout>} />
        <Route path="class" element={<Layout pageTitle='Class' ><ClassMaster /></Layout>} />
        <Route path="campus" element={<Layout pageTitle='Campus' ><CampusMaster /></Layout>} />
        <Route path="sessions" element={<Layout pageTitle='Sessions' ><SessionMaster /></Layout>} />
        <Route path="admissions" element={<Layout pageTitle='Admissions' ><Admissions /></Layout>} />
        <Route path="admissions/details" element={<Layout pageTitle='Admission Details' ><AdmissionDetails /></Layout>} />
        <Route path="points" element={<Layout pageTitle='Points' ><PointsSetup /></Layout>} />
        <Route path="registration-fee" element={<Layout pageTitle='Registration Fee' ><RegistrationFeeSetup /></Layout>} />
      </Route>
      <Route element={<StudentRoute />}>
        <Route path="my-application" element={<Layout pageTitle='My Application' ><MyApplication /></Layout>} />
      </Route>
    </Routes>
  )
}

export default SlugIndex