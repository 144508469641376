import axios from "axios";

const tokenFetch = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    accessKey: process.env.REACT_APP_ACCESS_KEY!,
    secretKey: process.env.REACT_APP_SECRET_KEY!
  }
})

const erpTokenFetch = axios.create({
  baseURL: process.env.REACT_APP_ERP_API_URL,
  headers: {
    subdomain: 'admin'
  }
});

erpTokenFetch.interceptors.request.use(config => {
  config.data = {
    ...config.data,
    device_type: 'web',
    locale: 'en',
    fcm_id: process.env.REACT_APP_ERP_FCM_ID,
    device_id: process.env.REACT_APP_ERP_DEVICE_ID,
  }
  return config
}, err => Promise.reject(err))

export {tokenFetch, erpTokenFetch}