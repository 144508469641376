import axios from "axios";

const mediaFetch = axios.create({
  headers: {
    bucketuid: process.env.REACT_APP_BUCKET_UID!,
    apikey: process.env.REACT_APP_MEDIA_ACCESS_KEY!,
    apisecret: process.env.REACT_APP_MEDIA_SECRET_KEY!
  }
})

export default mediaFetch