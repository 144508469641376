import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

const DateInput = ({
  name,
  control,
  label,
  errors = {},
  required = false,
  disabled = false,
  readOnly = false,
  minDate = null,
  maxDate = null
}: any) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            clearable={true}
            {...field}
            minDate={minDate}
            maxDate={maxDate}
            label={label}
            disabled={disabled}
            readOnly={readOnly}
            renderInput={(params: any) => (
              <TextField
                className='mb-2'
                {...params}
                required={required}
                size="small"
                fullWidth
                error={!!errors[name]}
                helperText={errors[name] ? errors[name].message : ' '}
              />
            )}

          />
        </LocalizationProvider>
      )}
    />
  )
}

export default DateInput