import { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify'

import {
  EditOutlined as EditIcon,
  DeleteOutlined as DeleteIcon,
  Close as CloseIcon,
  Done as DoneIcon,
  AddOutlined as AddIcon
} from '@mui/icons-material'

import { Button } from '../../../form-controls';

import { ConfirmDialog } from '../../../shared';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useQuery } from 'react-query'
import { FetchContext } from '../../../../context/FetchContext';
import { AuthContext } from '../../../../context/AuthContext';
import apiResources from '../../../../api/api.constants';
import Pagination from "../../../shared/Pagination";
import { Skeleton, IconButton } from '@mui/material';

const EditableRow = ({ item, handleDelete, refetch }: any) => {

  const [editMode, setEditMode] = useState(false)
  const { authFetch }: any = useContext(FetchContext)
  const { authState }: any = useContext(AuthContext)

  const schema = yup.object({
    fees: yup.number().required().min(0),
  }).required();

  const defaultValues = {
    fees: ''
  }

  const handleEsc = (e: any) => {
    if (e.keyCode === 27) setEditMode(false)
  }

  useEffect(() => {
    setValue('fees', item.fees)
    window.addEventListener('keyup', handleEsc)

    return () => window.removeEventListener('keyup', handleEsc)

  }, [editMode])

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const onSubmit = async ({ fees }: any) => {

    if (fees === 0) {
      handleDelete(item)
      setEditMode(false)
      return
    }

    try {
      const { data: response } = await authFetch.post(
        `${apiResources.updateClass}/${item.id}`,
        {
          name: item.name,
          fees
        }
      )
  
      if (response.success) {
        toast.success('Registration Fee updated successfully.')
        setEditMode(false)
        refetch()
      }

    } catch (err) {
      toast.error('Something went wrong. Please try again later.')
    }

  };

  return (
    <tr>
      <td>{item.name}</td>
      <td className='text-capitalize'>
        {!editMode
          ? item.fees
          : (
            <form id='edit-form' onSubmit={handleSubmit(onSubmit)}>
              <input
                autoFocus
                type='number'
                min='0'
                className={`native-input w-100 ${errors.fees ? 'error' : ''}`}
                placeholder='eg. 1000'
                {...register("fees")}
              />
            </form>
          )
        }
      </td>
      {!authState.userInfo.secretKey && (
        <td>
          {!editMode ? (
            <>
              <IconButton title='Edit' size="small" onClick={() => setEditMode(true)}>
                <EditIcon fontSize='small' />
              </IconButton>
              <IconButton title='Delete' className='ms-2' color='error' size="small" onClick={() => handleDelete(item)}>
                <DeleteIcon fontSize='small' />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton title='Cancel' size="small" tabIndex={-1} onClick={() => setEditMode(false)}>
                <CloseIcon fontSize='small' />
              </IconButton>
              <IconButton type='submit' form='edit-form' title='Update' className='ms-2' color='success' size="small">
                <DoneIcon fontSize='small' />
              </IconButton>
            </>
          )}
        </td>
      )}
    </tr>
  )
}


const RegistrationFeeSetup = () => {
  const [page, setPage] = useState(1)
  const [newRow, setNewRow] = useState(false)
  const [openPrompt, setOpenPrompt] = useState(false)
  const { authFetch, authErpFetch }: any = useContext(FetchContext)
  const { authState }: any =useContext(AuthContext)
  const activeItem = useRef<any>()

  const schema = yup.object({
    academicClassId: yup.number().required(),
    fees: yup.number().required().min(0),
  }).required();

  const defaultValues = {
    academicClassId: '',
    fees: ''
  }

  const getList = async (page: number = 1) => {
    const options = {
      filter: {
        "fees": true
      },
      range: {
        "page": page,
        "pageSize": 10
      }
    }
    const { data: response } = await authFetch.post(apiResources.getClasses, options)
    return response
  }

  const getErpClassMaster = async (page: number = 1) => {
    const { data: response } = await authErpFetch.get(apiResources.getErpClasses)
    const structuredResponse = {
      data: response.courses.map((i: any) => ({...i, status: true, fees: i.options.registration_fee })),
      page: 1,
      pageCount: 1
    }
    return structuredResponse
  }

  const list = useQuery(['classList', page], () => authState.userInfo.secretKey ? getErpClassMaster(page) : getList(page))

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const onSubmit = async (data: any) => {
    const name = list.data.find((x: any) => x.id === data.academicClassId).name

    try {
      const { data: response } = await authFetch.post(
        `${apiResources.updateClass}/${data.academicClassId}`,
        { name, fees: data.fees }
      )
  
      if (response.success) {
        toast.success('Registration fee added successfully.')
        setNewRow(false)
        reset()
        list.refetch()
      }
    } catch (err) {
      toast.error('Something went wrong. Please try again later.')
    }

  }

  const handleEsc = (e: any) => {
    if (e.keyCode === 27) {
      setNewRow(false)
      reset()
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', handleEsc)

    return () => window.removeEventListener('keyup', handleEsc)

  }, [])


  const togglePrompt = () => setOpenPrompt(!openPrompt)


  const handleCancel = () => {
    setNewRow(false)
    reset()
  }


  const handleDelete = (item: any) => {
    activeItem.current = item
    togglePrompt()
  }

  const handleDialogSubmit = async (res: boolean) => {
    if (res) {
      const { data: response } = await authFetch.post(`${apiResources.deleteFees}/${activeItem.current?.id}`)
      if (response.success) {
        list.refetch()
      }
    }

    togglePrompt()
  }

  return (
    <section className="listing-container mt-3">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="listing-actions">
          <input className="form-control" placeholder="Search for a fees..." />
          <Button type='submit' className='ms-2'>Submit</Button>
          {!authState.userInfo.secretKey && (
            <Button onClick={() => setNewRow(!newRow)} className='ms-2' color='success' >
              <AddIcon />
            </Button>
          )}
        </div>
      </form>
      <div className='table-responsive'>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Class</th>
              <th scope="col">Fees</th>
              {!authState.userInfo.secretKey && (
                <th scope="col"></th>
              )}
            </tr>
          </thead>
          <tbody>
            {newRow && (
              <tr>
                <td>
                  <form id='new-form' onSubmit={handleSubmit(onSubmit)}>
                    <select autoFocus className={`native-select w-100 ${errors.academicClassId ? 'error' : ''}`} {...register('academicClassId')}>
                      <option value=''>Choose...</option>
                      {list.data?.map((item: any) => (<option key={item.id} value={item.id}>{item.name}</option>))}
                    </select>
                  </form>
                </td>
                <td>
                  <input
                    form='new-form'
                    type='number'
                    min='0'
                    className={`native-input w-100 ${errors.fees ? 'error' : ''}`}
                    placeholder='eg. 1000'
                    {...register("fees")}
                  />
                </td>
                <td>
                  <IconButton title='Cancel' size="small" tabIndex={-1} onClick={handleCancel}>
                    <CloseIcon fontSize='small' />
                  </IconButton>
                  <IconButton type='submit' form='new-form' title='Add' className='ms-2' color='success' size="small">
                    <DoneIcon fontSize='small' />
                  </IconButton>
                </td>
              </tr>
            )}
            {list.isLoading ? (
              <tr>
                <td><Skeleton variant='text' /></td>
                <td><Skeleton variant='text' /></td>
                {!authState.userInfo.secretKey && (
                  <td className='d-flex'>
                    <Skeleton variant='circular' width={24} height={24} />
                    <Skeleton variant='circular' width={24} height={24} className='ms-2' />
                  </td>
                )}
              </tr>
            ) : list.data?.data?.length ? (
              list.data?.data?.map((item: any) => (
                <EditableRow key={item.id} item={item} refetch={list.refetch} handleDelete={handleDelete} />
              ))
            ) : !newRow ? (
              <tr>
                <td colSpan={3}>No Records Found...</td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
      <Pagination totalPages={list.data?.pageCount} onChange={setPage} />
      {openPrompt && (
        <ConfirmDialog
          open={openPrompt}
          title='Are you sure?'
          description={`Do you really want to make registration fees 0 for class ${activeItem.current.name}?`}
          primary='Make it free'
          primaryColor='error'
          onTrigger={handleDialogSubmit}
        />
      )}
    </section>
  )
}

export default RegistrationFeeSetup