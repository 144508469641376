import { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify'
import {
  IconButton, Skeleton
} from '@mui/material';

import {
  EditOutlined as EditIcon,
  DeleteOutlined as DeleteIcon,
  Close as CloseIcon,
  Done as DoneIcon,
  AddOutlined as AddIcon
} from '@mui/icons-material'

import { Button, Switch } from '../../../form-controls';

import { ConfirmDialog } from '../../../shared';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useQuery } from 'react-query'
import { FetchContext } from '../../../../context/FetchContext';
import apiResources from '../../../../api/api.constants';
import Pagination from "../../../shared/Pagination";
import { AuthContext } from '../../../../context/AuthContext';

const EditableRow = ({ item, handleDelete, refetch }: any) => {

  const [editMode, setEditMode] = useState(false)
  const { authFetch }: any = useContext(FetchContext)
  const { authState }: any = useContext(AuthContext)

  const schema = yup.object({
    academicClass: yup.string().required(),
  }).required();

  const defaultValues = {
    academicClass: '',
    visible: true
  }

  const handleEsc = (e: any) => {
    if (e.keyCode === 27) setEditMode(false)
  }

  useEffect(() => {
    setValue('academicClass', item.name)
    setValue('visible', item.status)
    window.addEventListener('keyup', handleEsc)

    return () => window.removeEventListener('keyup', handleEsc)

  }, [editMode])

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const onSubmit = async ({ academicClass, visible }: any) => {
    try {
      const { data: response } = await authFetch.post(
        `${apiResources.updateClass}/${item.id}`,
        {
          name: academicClass,
          status: visible
        }
      )
  
      if (response.success) {
        toast.success('Class updated successfully.')
        setEditMode(false)
        refetch()
      }
    } catch (err) {
      toast.error('Something went wrong. Please try again later.')
    }

  };

  return (
    <tr>
      <td>
        {!editMode
          ? item.name
          : (
            <form id="class-form" onSubmit={handleSubmit(onSubmit)}>
              <input
                autoFocus
                className={`native-input w-100 ${errors.academicClass ? 'error' : ''}`}
                placeholder='eg. Class X'
                {...register("academicClass")}
              />
            </form>
          )
        }
      </td>
      <td className='text-capitalize'>
        {!editMode
          ? item.status ? 'Active' : 'In-active'
          : (
            <form id="class-form" onSubmit={handleSubmit(onSubmit)}>
              <Switch name='visible' control={control} />
            </form>
          )
        }
      </td>
      {!authState.userInfo.secretKey && (
        <td>
          {!editMode ? (
            <>
              <IconButton title='Edit' size="small" onClick={() => setEditMode(true)}>
                <EditIcon fontSize='small' />
              </IconButton>
              <IconButton title='Delete' className='ms-2' color='error' size="small" onClick={() => handleDelete(item)}>
                <DeleteIcon fontSize='small' />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton title='Cancel' size="small" onClick={() => setEditMode(false)}>
                <CloseIcon fontSize='small' />
              </IconButton>
              <IconButton type='submit' form='class-form' title='Update' className='ms-2' color='success' size="small">
                <DoneIcon fontSize='small' />
              </IconButton>
            </>
          )}
        </td>
      )}
    </tr>
  )
}


const ClassMaster = () => {
  const [page, setPage] = useState(1)
  const [newRow, setNewRow] = useState(false)
  const [openPrompt, setOpenPrompt] = useState(false)
  const { authState }: any = useContext(AuthContext)
  const { authFetch, authErpFetch }: any = useContext(FetchContext)
  const activeItem = useRef<any>()

  const schema = yup.object({
    academicClass: yup.string().required(),
  }).required();

  const defaultValues = {
    academicClass: ''
  }

  const getList = async (page: number = 1) => {
    const options = {
      range: {
        "page": page,
        "pageSize": 10
      }
    }
    const { data: response } = await authFetch.post(apiResources.getClasses, options)
    return response
  }

  const getErpClassMaster = async (page: number = 1) => {
    const { data: response } = await authErpFetch.get(apiResources.getErpClasses)
    const structuredResponse = {
      data: response.courses.map((i: any) => ({...i, status: true})),
      page: 1,
      pageCount: 1
    }
    return structuredResponse
  }

  const list = useQuery(['classList', page], () => authState.userInfo.secretKey ? getErpClassMaster(page) : getList(page))

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const onSubmit = async ({ academicClass }: any) => {
    try {
      const { data: response } = await authFetch.post(
        apiResources.createClass,
        { name: academicClass }
      )
  
      if (response.success) {
        toast.success('Class added successfully.')
        setNewRow(false)
        reset()
        list.refetch()
      }
    } catch (err) {
      toast.error('Something went wrong. Please try again later.')
    }

  }

  const handleEsc = (e: any) => {
    if (e.keyCode === 27) {
      setNewRow(false)
      reset()
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', handleEsc)

    return () => window.removeEventListener('keyup', handleEsc)

  }, [])


  const togglePrompt = () => setOpenPrompt(!openPrompt)


  const handleCancel = () => {
    setNewRow(false)
    reset()
  }


  const handleDelete = (item: any) => {
    togglePrompt()
    activeItem.current = item
  }

  const handleDialogSubmit = async (res: boolean) => {
    if (res) {
      const { data: response } = await authFetch.post(`${apiResources.deleteClass}/${activeItem.current?.id}`)
      if (response.success) {
        list.refetch()
      }
    }

    togglePrompt()
  }

  return (
    <section className="listing-container mt-3">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="listing-actions">
          <input className="form-control" placeholder="Search for a class..." />
          <Button type='submit' className='ms-2'>Submit</Button>
          {!authState.userInfo.secretKey && (
            <Button onClick={() => setNewRow(!newRow)} className='ms-2' color='success' >
              <AddIcon />
            </Button>
          )}
        </div>
      </form>
      <div className='table-responsive'>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Class</th>
              <th scope="col">Status</th>
              {!authState.userInfo.secretKey && (
                <th scope="col"></th>
              )}
            </tr>
          </thead>
          <tbody>
            {newRow && (
              <tr>
                <td>
                  <form id='new-class-form' onSubmit={handleSubmit(onSubmit)}>
                    <input
                      autoFocus
                      className={`native-input w-100 ${errors.academicClass ? 'error' : ''}`}
                      placeholder='eg. Class X'
                      {...register("academicClass")}
                    />
                  </form>
                </td>
                <td>
                  Active
                </td>
                <td>
                  <IconButton title='Cancel' size="small" onClick={handleCancel}>
                    <CloseIcon fontSize='small' />
                  </IconButton>
                  <IconButton type='submit' form='new-class-form' title='Add' className='ms-2' color='success' size="small">
                    <DoneIcon fontSize='small' />
                  </IconButton>
                </td>
              </tr>
            )}
            {list.isLoading ? (
              <tr>
                <td><Skeleton variant='text' /></td>
                <td><Skeleton variant='text' /></td>
                {!authState.userInfo.secretKey && (
                  <td className='d-flex'>
                    <Skeleton variant='circular' width={24} height={24} />
                    <Skeleton variant='circular' width={24} height={24} className='ms-2' />
                  </td>
                )}
              </tr>
            ) : list.data?.data?.length ? (
              list.data?.data?.map((item: any) => (
                <EditableRow key={item.id} item={item} refetch={list.refetch} handleDelete={handleDelete} />
              ))
            ) : !newRow ? (
              <tr>
                <td colSpan={3}>No Records Found...</td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
      <Pagination totalPages={list.data?.pageCount} onChange={setPage} />
      {openPrompt && (
        <ConfirmDialog
          open={openPrompt}
          title='Are you sure?'
          description={`Do you really want to delete ${activeItem.current.name} class? This process can not be undone.`}
          primary='Delete'
          primaryColor='error'
          onTrigger={handleDialogSubmit}
        />
      )}
    </section>
  )
}

export default ClassMaster