import './style.css'
import DoneImg from '../../assets/images/done.svg'

const SuccessfulSubmission  = () => {
  return (
    <section className='success-response'>
      <div className='success-container'>
        <img className='success-img' src={DoneImg} alt="Confirmation" />
        <h1>Thank You!</h1>
        <p>Application form submitted successfully. We will get back to you shortly.</p>
      </div>
    </section>
  )
}

export default SuccessfulSubmission