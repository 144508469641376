import { useContext, useEffect, useRef, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { toast } from 'react-toastify'

import {
  EditOutlined as EditIcon,
  DeleteOutlined as DeleteIcon,
  Close as CloseIcon,
  Done as DoneIcon,
  AddOutlined as AddIcon
} from '@mui/icons-material'

import { Button } from '../../../form-controls';

import { ConfirmDialog } from '../../../shared';

import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from 'react-query'
import { FetchContext } from '../../../../context/FetchContext';
import apiResources from '../../../../api/api.constants';
import Pagination from "../../../shared/Pagination";
import { Skeleton } from '@mui/material';

const EditableRow = ({ item, handleDelete, refetch }: any) => {

  const [editMode, setEditMode] = useState(false)
  const { authFetch }: any = useContext(FetchContext)

  const schema = yup.object({
    startPoint: yup.number().required().min(0),
    endPoint: yup.number().required().min(1).moreThan(yup.ref('startPoint')),
    unit: yup.string().required(),
    point: yup.number().required().min(0),
  }).required();

  const defaultValues = {
    startPoint: 0,
    endPoint: 1,
    unit: 'miles',
    point: '',
  }

  const handleEsc = (e: any) => {
    if (e.keyCode === 27) setEditMode(false)
  }

  useEffect(() => {
    setValue('startPoint', item.startPoint)
    setValue('endPoint', item.endPoint)
    setValue('unit', item.unit)
    setValue('point', item.point)
    window.addEventListener('keyup', handleEsc)

    return () => window.removeEventListener('keyup', handleEsc)

  }, [editMode])

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const onSubmit = async (data: any) => {
    try {
      const { data: response } = await authFetch.post(
        `${apiResources.updatePoint}/${item.id}`,
        data
      )
  
      if (response.success) {
        toast.success('Points updated successfully.')
        setEditMode(false)
        refetch()
      }
    } catch (err) {
      toast.error('Something went wrong. Please try again later.')
    }

  };

  return (
    <tr>
      <td>
        {!editMode
          ? `${item.startPoint} to ${item.endPoint} ${item.unit}`
          : (
            <form id="edit-form" onSubmit={handleSubmit(onSubmit)}>
              <input
                type='number'
                min='0'
                autoFocus
                className={`native-input ${errors.startPoint ? 'error' : ''}`}
                placeholder='eg. 0'
                {...register("startPoint")}
              />
              <span className='mx-2'>to</span>
              <input
                type='number'
                min='0'
                className={`native-input ${errors.endPoint ? 'error' : ''}`}
                placeholder='eg. 5'
                {...register("endPoint")}
              />
              <select className='ms-2 native-select' {...register('unit')}>
                <option value='miles'>Miles</option>
                <option value='km'>KM</option>
              </select>
            </form>
          )
        }
      </td>
      <td className='text-capitalize'>
        {!editMode
          ? item.point
          : (
            <form id="class-form" onSubmit={handleSubmit(onSubmit)}>
              <input
                form='edit-form'
                type='number'
                min='0'
                className={`native-input ${errors.point ? 'error' : ''}`}
                placeholder='eg. 10'
                {...register("point")}
              />
            </form>
          )
        }
      </td>
      <td>
        {!editMode ? (
          <>
            <IconButton title='Edit' size="small" onClick={() => setEditMode(true)}>
              <EditIcon fontSize='small' />
            </IconButton>
            <IconButton title='Delete' className='ms-2' color='error' size="small" onClick={() => handleDelete(item)}>
              <DeleteIcon fontSize='small' />
            </IconButton>
          </>
        ) : (
          <>
            <IconButton title='Cancel' size="small" tabIndex={-1} onClick={() => setEditMode(false)}>
              <CloseIcon fontSize='small' />
            </IconButton>
            <IconButton type='submit' form='edit-form' title='Update' className='ms-2' color='success' size="small">
              <DoneIcon fontSize='small' />
            </IconButton>
          </>
        )}
      </td>
    </tr>
  )
}


const PointsSetup = () => {
  const [page, setPage] = useState(1)
  const [newRow, setNewRow] = useState(false)
  const [openPrompt, setOpenPrompt] = useState(false)
  const { authFetch }: any = useContext(FetchContext)
  const activeItem = useRef<any>()

  const schema = yup.object({
    startPoint: yup.number().required().min(0),
    endPoint: yup.number().required().min(1).moreThan(yup.ref('startPoint')),
    unit: yup.string().required(),
    point: yup.number().required().min(0),
  }).required();

  const defaultValues = {
    startPoint: '',
    endPoint: '',
    unit: 'miles',
    point: '',
  }

  const getList = async (page: number = 1) => {
    const options = {
      range: {
        "page": page,
        "pageSize": 10
      }
    }
    const { data: response } = await authFetch.post(apiResources.getPoints, options)
    return response
  }

  const list = useQuery(['pointList', page], () => getList(page))

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const onSubmit = async (data: any) => {
    try {
      const { data: response } = await authFetch.post(
        apiResources.createPoint,
        data
      )
  
      if (response.success) {
        toast.success('Points added successfully.')
        setNewRow(false)
        reset()
        list.refetch()
      }
    } catch (err) {
      toast.error('Something went wrong. Please try again later.')
    }

  }

  const handleEsc = (e: any) => {
    if (e.keyCode === 27) {
      setNewRow(false)
      reset()
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', handleEsc)

    return () => window.removeEventListener('keyup', handleEsc)

  }, [])


  const togglePrompt = () => setOpenPrompt(!openPrompt)


  const handleCancel = () => {
    setNewRow(false)
    reset()
  }


  const handleDelete = (item: any) => {
    togglePrompt()
    activeItem.current = item
  }

  const handleDialogSubmit = async (res: boolean) => {
    if (res) {
      const { data: response } = await authFetch.post(`${apiResources.deletePoint}/${activeItem.current?.id}`)
      if (response.success) {
        list.refetch()
      }
    }

    togglePrompt()
  }

  return (
    <section className="listing-container mt-3">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="listing-actions">
          <input className="form-control" placeholder="Search for a point..." />
          <Button type='submit' className='ms-2'>Submit</Button>
          <Button onClick={() => setNewRow(!newRow)} className='ms-2' color='success' >
            <AddIcon />
          </Button>
        </div>
      </form>
      <div className='table-responsive'>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Distance</th>
              <th scope="col">Points</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {newRow && (
              <tr>
                <td>
                  <form id='new-form' onSubmit={handleSubmit(onSubmit)} noValidate>
                    <input
                      type='number'
                      min='0'
                      autoFocus
                      className={`native-input ${errors.startPoint ? 'error' : ''}`}
                      placeholder='eg. 0'
                      {...register("startPoint")}
                    />
                    <span className='mx-2'>to</span>
                    <input
                      type='number'
                      min='0'
                      className={`native-input ${errors.endPoint ? 'error' : ''}`}
                      placeholder='eg. 5'
                      {...register("endPoint")}
                    />
                    <select className='ms-2 native-select' {...register('unit')}>
                      <option value='miles'>Miles</option>
                      <option value='km'>KM</option>
                    </select>
                  </form>
                </td>
                <td>
                  <input
                    form='new-form'
                    type='number'
                    min='0'
                    className={`native-input ${errors.point ? 'error' : ''}`}
                    placeholder='eg. 10'
                    {...register("point")}
                  />
                </td>
                <td>
                  <IconButton title='Cancel' size="small" tabIndex={-1} onClick={handleCancel}>
                    <CloseIcon fontSize='small' />
                  </IconButton>
                  <IconButton type='submit' form='new-form' title='Add' className='ms-2' color='success' size="small">
                    <DoneIcon fontSize='small' />
                  </IconButton>
                </td>
              </tr>
            )}
            {list.isLoading ? (
              <tr>
                <td><Skeleton variant='text' /></td>
                <td><Skeleton variant='text' /></td>
                <td className='d-flex'>
                  <Skeleton variant='circular' width={24} height={24} />
                  <Skeleton variant='circular' width={24} height={24} className='ms-2' />
                </td>
              </tr>
            ) : list.data?.data?.length ? (
              list.data?.data?.map((item: any) => (
                <EditableRow key={item.id} item={item} refetch={list.refetch} handleDelete={handleDelete} />
              ))
            ) : !newRow ? (
              <tr>
                <td colSpan={3}>No Records Found...</td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
      <Pagination totalPages={list.data?.pageCount} onChange={setPage} />
      {openPrompt && (
        <ConfirmDialog
          open={openPrompt}
          title='Are you sure?'
          description={`Do you really want to delete ${activeItem.current.name} point? This process can not be undone.`}
          primary='Delete'
          primaryColor='error'
          onTrigger={handleDialogSubmit}
        />
      )}
    </section>
  )
}

export default PointsSetup