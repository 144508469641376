import { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom"
import { useForm } from "react-hook-form";
import {
  Button,
  Input,
  DateInput,
  Select,
  RadioGroup,
  GeoAutocomplete
} from "../../../form-controls";
import { FetchContext } from '../../../../context/FetchContext'
import { AuthContext } from "../../../../context/AuthContext";
import { useQuery } from 'react-query'
import apiResources from "../../../../api/api.constants";

const Tab = ({ title, activeState, state, handleActiveTab }: any) => {
  return (
    <button className={`nav-link ${activeState === state ? 'active' : ''}`} role="tab" onClick={() => handleActiveTab(state)}>{title}</button>
  )
}

const defaultValues = {
  schoolName: '',
  campus: '',
  academicYear: '',
  firstName: '',
  lastName: '',
  studentEmail: '',
  dateOfBirth: null,
  admissionInClass: '',
  gender: 'male',
  nationality: '',
  currentSchool: '',
  fatherFirstName: '',
  fatherLastName: '',
  fatherMobileNo: '',
  fatherEmail: '',
  fatherQualification: '',
  fatherProfession: '',
  fatherAnnualIncome: '',
  fatherOfficeName: '',
  motherFirstName: '',
  motherLastName: '',
  motherMobileNo: '',
  motherEmail: '',
  motherQualification: '',
  motherProfession: '',
  motherAnnualIncome: '',
  motherOfficeName: '',
  address: '',
  latitude: '',
  longitude: '',
  enquirySource: '',
  isStaffChild: 'no',
  hasJointFamily: 'no',
  hasSingleParent: 'no',
  isDisabledChild: 'no',
}

const AdmissionDetails = () => {

  const { authFetch, authErpFetch }: any = useContext(FetchContext)
  const { authState }: any = useContext(AuthContext)
  const [activeTab, setActiveTab] = useState(0);
  const { state }: any = useLocation()

  const getSessionMaster = async () => {
    const { data: response } = await authFetch.post(apiResources.getSessions)
    return response.data
  }

  const getErpSessionMaster = async () => {
    const { data: response } = await authErpFetch.get(apiResources.getErpSessions)
    return response
  }

  let sessionOptions = []
  const sessionMaster = useQuery('sessionList', authState.userInfo.secretKey ? getErpSessionMaster : getSessionMaster)
  if (sessionMaster.isFetched) {
    sessionOptions = sessionMaster.data?.map((item: any) => ({ label: item.name, value: item.id }))
  }

  const getCampusMaster = async () => {
    const { data: response } = await authFetch.post(apiResources.getCampuses)
    return response.data
  }

  const getErpCampusMaster = async () => {
    const { data: response } = await authErpFetch.get(apiResources.getErpCampuses)
    return response.campus.data
  }

  let campusOptions = []
  const campusMaster = useQuery('campusList', authState.userInfo.secretKey ? getErpCampusMaster : getCampusMaster)
  if (campusMaster.isFetched) {
    campusOptions = campusMaster.data?.map((item: any) => ({ label: item.name, value: item.id }))
  }

  const getClassMaster = async () => {
    const { data: response } = await authFetch.post(apiResources.getClasses)
    return response.data
  }

  const getErpClassMaster = async () => {
    const { data: response } = await authErpFetch.get(apiResources.getErpClasses)
    return response.courses
  }

  let classOptions = []
  const classMaster = useQuery('classList', authState.userInfo.secretKey ? getErpClassMaster : getClassMaster)
  if (classMaster.isFetched) {
    classOptions = classMaster.data.map((item: any) => ({ label: item.name, value: item.id }))
  }

  const handleActiveTab = (state: any) => setActiveTab(state)

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm({ defaultValues, mode: 'all' })

  useEffect(() => {
    if (state) {
      const initialData = state.formJSON[0]
      setValue('schoolName', initialData.schoolName)
      setValue('campus', initialData.campus)
      setValue('academicYear', initialData.academicYear)
      setValue('firstName', initialData.firstName)
      setValue('lastName', initialData.lastName)
      setValue('studentEmail', initialData.studentEmail)
      setValue('dateOfBirth', initialData.dateOfBirth)
      setValue('admissionInClass', initialData.admissionInClass)
      setValue('gender', initialData.gender)
      setValue('nationality', initialData.nationality)
      setValue('currentSchool', initialData.currentSchool)
      setValue('fatherFirstName', initialData.fatherFirstName)
      setValue('fatherLastName', initialData.fatherLastName)
      setValue('fatherMobileNo', initialData.fatherMobileNo)
      setValue('fatherEmail', initialData.fatherEmail)
      setValue('fatherQualification', initialData.fatherQualification)
      setValue('fatherProfession', initialData.fatherProfession)
      setValue('fatherAnnualIncome', initialData.fatherAnnualIncome)
      setValue('fatherOfficeName', initialData.fatherOfficeName)
      setValue('motherFirstName', initialData.motherFirstName)
      setValue('motherLastName', initialData.motherLastName)
      setValue('motherMobileNo', initialData.motherMobileNo)
      setValue('motherEmail', initialData.motherEmail)
      setValue('motherQualification', initialData.motherQualification)
      setValue('motherProfession', initialData.motherProfession)
      setValue('motherAnnualIncome', initialData.motherAnnualIncome)
      setValue('motherOfficeName', initialData.motherOfficeName)
      setValue('address', initialData.address)
      setValue('latitude', initialData.latitude)
      setValue('longitude', initialData.longitude)
      setValue('enquirySource', initialData.enquirySource)
      setValue('isStaffChild', initialData.isStaffChild)
      setValue('hasJointFamily', initialData.hasJointFamily)
      setValue('hasSingleParent', initialData.hasSingleParent)
      setValue('isDisabledChild', initialData.isDisabledChild)
    }
  }, [state])

  const onSubmit = async (data: any) => {
    console.log(data)
    const payload = {
      formJSON: [data]
    }

    const formData = new FormData()
    formData.append('first_name', data.firstName)
    formData.append('locale', 'en')
    const erpPayload = {
      // campus_id: 1,
      // student_type: 'new',
      first_name: data.firstName,
      // middle_name: null,
      // last_name: data.lastName,
      // first_guardian_name: `${data.fatherFirstName} ${data.fatherLastName}`,
      // first_guardian_relation: 'father',
      // second_guardian_name: `${data.motherFirstName} ${data.motherLastName}`,
      // second_guardian_relation: 'mother',
      // parent_type: 'new',
      // first_guardian_contact_number_1: data.fatherMobileNo,
      // date_of_birth: data.dateOfBirth,
      // date_of_registration: new Date(),
      // contact_number: data.fatherMobileNo,
      // gender: data.gender,
      // course_id: data.admissionInClass,
      locale: 'en',
    }

    const { data: response} = await authFetch.post(`${apiResources.updateUser}/${state.id}`, payload)
    if(response.success) {
      // Send data to ERP
      // const resFromErp = await authErpFetch.post(`https://api.erp.schoolzpro.net.in/v1/student/dedf0275-8f65-49c2-bb62-57cb483e47d3`, formData)
      // const resFromErp = await authErpFetch.post(`${apiResources.sendDataToErp}/${'1299'}/update/status`, erpPayload)
      // console.log(resFromErp)
      toast.success("Application updated successfully.")
    }

  }

  return (
    <section className="mt-3">
      <div className="row">
        <div className="col-md-3 mb-3">
          <div className="nav flex-column nav-pills tabs mb-3" role="tablist" aria-orientation="vertical">
            {[`School Details`, `Student Details`, `Father's Details`, `Mother's Details`, `Address`, `Other Details`].map((item, i) => (
              <Tab key={i} title={item} state={i} activeState={activeTab} handleActiveTab={handleActiveTab} />
            ))}
          </div>
          <Button type='submit' form='student-details' fullWidth>Save</Button>
        </div>
        <div className="col-md-9 pl-lg-0">
          <div className="tab-content tab-panel pt-4" id="v-pills-tabContent">
            <form id='student-details' className="container" noValidate onSubmit={handleSubmit(onSubmit)}>
              <div className={`tab-pane row ${activeTab !== 0 ? 'd-none' : ''}`}>
                <div className='col-12'>
                  <Input
                    required
                    name='schoolName'
                    label='School Name'
                    control={control}
                    errors={errors}
                    readOnly
                    rules={{
                      required: { value: true, message: 'School name is required.' }
                    }}
                  />
                </div>
                <div className='col-12'>
                  <Select
                    required
                    name='campus'
                    label='Campus'
                    control={control}
                    errors={errors}
                    options={campusOptions}
                    rules={{
                      required: { value: true, message: 'Campus is required.' }
                    }}
                  />
                </div>
                <div className='col-12'>
                  <Select
                    required
                    name='academicYear'
                    label='Academic Year'
                    control={control}
                    errors={errors}
                    options={sessionOptions}
                    rules={{
                      required: { value: true, message: 'Academic Year is required.' }
                    }}
                  />
                </div>
              </div>
              <div className={`tab-pane row ${activeTab !== 1 ? 'd-none' : ''}`}>
                <div className='col-lg-6'>
                  <Input
                    required
                    name='firstName'
                    label='First Name'
                    control={control}
                    errors={errors}
                    rules={{
                      required: { value: true, message: 'First name is required.' }
                    }}
                  />
                </div>
                <div className='col-lg-6'>
                  <Input
                    required
                    name='lastName'
                    label='Last Name'
                    control={control}
                    errors={errors}
                    rules={{
                      required: { value: true, message: 'Last name is required.' }
                    }}
                  />
                </div>
                <div className='col-lg-6'>
                  <Input
                    name='studentEmail'
                    label='Email'
                    control={control}
                    errors={errors}
                    rules={{
                      required: { value: true, message: 'Email is required.' },
                      email: { value: true, message: 'Invalid Email' }
                    }}
                  />
                </div>
                <div className='col-lg-6'>
                  <DateInput
                    required
                    maxDate={new Date()}
                    name='dateOfBirth'
                    label='Date of Birth'
                    control={control}
                    errors={errors}
                    rules={{
                      required: { value: true, message: 'DOB is required.' }
                    }}
                  />
                </div>
                <div className='col-lg-6'>
                  <Select
                    required
                    name='admissionInClass'
                    label='Admission in Class'
                    control={control}
                    errors={errors}
                    options={classOptions}
                    rules={{
                      required: { value: true, message: 'Class is required.' }
                    }}
                  />
                </div>
                <div className='col-lg-6'>
                  <Select
                    required
                    name='gender'
                    label='Gender'
                    control={control}
                    errors={errors}
                    options={[
                      { label: 'Male', value: 'male' },
                      { label: 'Female', value: 'female' }
                    ]}
                  />
                </div>
                <div className='col-lg-6'>
                  <Input
                    required
                    name='nationality'
                    label='Nationality'
                    control={control}
                    errors={errors}
                    rules={{
                      required: { value: true, message: 'Nationality is required.' }
                    }}
                  />
                </div>
                <div className='col-lg-6'>
                  <Input
                    required
                    name='currentSchool'
                    label='Current School/Play School'
                    control={control}
                    rules={{
                      required: { value: true, message: 'Current school is required.' }
                    }}
                  />
                </div>
              </div>
              <div className={`tab-pane row ${activeTab !== 2 ? 'd-none' : ''}`}>
                <div className='col-lg-6'>
                  <Input
                    required
                    name='fatherFirstName'
                    label='First Name'
                    control={control}
                    errors={errors}
                    rules={{
                      required: { value: true, message: 'First name is required.' }
                    }}
                  />
                </div>
                <div className='col-lg-6'>
                  <Input
                    required
                    name='fatherLastName'
                    label='Last Name'
                    control={control}
                    errors={errors}
                    rules={{
                      required: { value: true, message: 'Last name is required.' }
                    }}
                  />
                </div>
                <div className='col-lg-6'>
                  <Input
                    name='fatherMobileNo'
                    label='Mobile Number'
                    control={control}
                    errors={errors}
                    rules={{
                      required: { value: true, message: 'Mobile number is required.' }
                    }}
                  />
                </div>
                <div className='col-lg-6'>
                  <Input
                    name='fatherEmail'
                    label='Email'
                    control={control}
                    errors={errors}
                    rules={{
                      required: { value: true, message: 'Email is required.' }
                    }}
                  />
                </div>
                <div className='col-lg-6'>
                  <Select
                    required
                    name='fatherQualification'
                    label='Qualification'
                    control={control}
                    errors={errors}
                    options={[
                      { label: 'Businessmen', value: 'businessmen' },
                      { label: 'Job', value: 'job' }
                    ]}
                    rules={{
                      required: { value: true, message: 'Qualification is required.' }
                    }}
                  />
                </div>
                <div className='col-lg-6'>
                  <Input
                    name='fatherProfession'
                    label='Profession'
                    control={control}
                    errors={errors}
                    rules={{
                      required: { value: true, message: 'Profession is required.' }
                    }}
                  />
                </div>
                <div className='col-lg-6'>
                  <Input
                    name='fatherAnnualIncome'
                    label='Annual income in Rupees'
                    control={control}
                    errors={errors}
                    rules={{
                      required: { value: true, message: 'Income is required.' }
                    }}
                  />
                </div>
                <div className='col-lg-6'>
                  <Input
                    name='fatherOfficeName'
                    label='Office Name'
                    control={control}
                    errors={errors}
                  />
                </div>
              </div>
              <div className={`tab-pane row ${activeTab !== 3 ? 'd-none' : ''}`}>
                <div className='col-lg-6'>
                  <Input
                    required
                    name='motherFirstName'
                    label='First Name'
                    control={control}
                    errors={errors}
                    rules={{
                      required: { value: true, message: 'First name is required.' }
                    }}
                  />
                </div>
                <div className='col-lg-6'>
                  <Input
                    required
                    name='motherLastName'
                    label='Last Name'
                    control={control}
                    errors={errors}
                    rules={{
                      required: { value: true, message: 'Last name is required.' }
                    }}
                  />
                </div>
                <div className='col-lg-6'>
                  <Input
                    name='motherMobileNo'
                    label='Mobile Number'
                    control={control}
                    errors={errors}
                    rules={{
                      required: { value: true, message: 'Mobile number is required.' }
                    }}
                  />
                </div>
                <div className='col-lg-6'>
                  <Input
                    name='motherEmail'
                    label='Email'
                    control={control}
                    errors={errors}
                    rules={{
                      required: { value: true, message: 'Email is required.' }
                    }}
                  />
                </div>
                <div className='col-lg-6'>
                  <Select
                    required
                    name='motherQualification'
                    label='Qualification'
                    control={control}
                    errors={errors}
                    options={[
                      { label: 'Businessmen', value: 'businessmen' },
                      { label: 'Job', value: 'job' }
                    ]}
                    rules={{
                      required: { value: true, message: 'Qualification is required.' }
                    }}
                  />
                </div>
                <div className='col-lg-6'>
                  <Input
                    name='motherProfession'
                    label='Profession'
                    control={control}
                    errors={errors}
                    rules={{
                      required: { value: true, message: 'Profession is required.' }
                    }}
                  />
                </div>
                <div className='col-lg-6'>
                  <Input
                    name='motherAnnualIncome'
                    label='Annual income in Rupees'
                    control={control}
                    errors={errors}
                  />
                </div>
                <div className='col-lg-6'>
                  <Input
                    name='motherOfficeName'
                    label='Office Name'
                    control={control}
                    errors={errors}
                  />
                </div>
              </div>
              <div className={`tab-pane row ${activeTab !== 4 ? 'd-none' : ''}`}>
                <div className='col-lg-12'>
                  <GeoAutocomplete
                    control={control}
                    errors={errors}
                    rules={{ required: { value: true, message: 'Address is required.' } }}
                    setValue={setValue}
                    name='address'
                    label='Address'
                  />
                </div>
              </div>
              <div className={`tab-pane row ${activeTab !== 5 ? 'd-none' : ''}`}>
                <div className='col-lg-12'>
                  <Select
                    required
                    name='enquirySource'
                    label='Enquiry Source'
                    control={control}
                    errors={errors}
                    options={[
                      { label: 'Internet', value: 'internet' },
                      { label: 'Offline', value: 'offline' }
                    ]}
                  />
                </div>
                <div className='col-lg-6'>
                  <RadioGroup
                    name='isStaffChild'
                    label='Whether child is a staff ward'
                    control={control}
                    errors={errors}
                    options={[
                      { label: 'Yes', value: 'yes' },
                      { label: 'No', value: 'no' }
                    ]}
                  />
                </div>
                <div className='col-lg-6'>
                  <RadioGroup
                    name='hasJointFamily'
                    label='Joint Family'
                    control={control}
                    errors={errors}
                    options={[
                      { label: 'Yes', value: 'yes' },
                      { label: 'No', value: 'no' }
                    ]}
                  />
                </div>
                <div className='col-lg-6'>
                  <RadioGroup
                    name='hasSingleParent'
                    label='Are you Single Parent'
                    control={control}
                    errors={errors}
                    options={[
                      { label: 'Yes', value: 'yes' },
                      { label: 'No', value: 'no' }
                    ]}
                  />
                </div>
                <div className='col-lg-6'>
                  <RadioGroup
                    name='isDisabledChild'
                    label='Physically/Mentally Challenged'
                    control={control}
                    errors={errors}
                    options={[
                      { label: 'Yes', value: 'yes' },
                      { label: 'No', value: 'no' }
                    ]}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div >
    </section >
  )
};

export default AdmissionDetails;
